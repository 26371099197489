/* eslint-disable import/no-unresolved */
import type { Dispatch, FunctionComponent } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import type { EmblaCarouselType } from 'embla-carousel-react';
import ExecutionEnvironment from 'exenv';

import { cn } from 'helpers/classnames';
import useWindowSize from 'hooks/useWindowSize';
import useMartyContext from 'hooks/useMartyContext';
import { getPDPTrackingPropsFormatted } from 'helpers/ProductUtils';
import type { FeaturedImage, ImageType, PDPCarouselRef, PDPFeaturedVideo, VideoType } from 'types/product';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import MelodyVideoPlayer from 'components/common/melodyVideo/MelodyVideoPlayer';
import ImageZoom from 'components/common/zoom/ImageZoom';
import { track } from 'apis/amethyst';
import { evImageSwipe } from 'events/product';
import { preloadImages } from 'helpers/imageUtils';
import { useInAssignment } from 'hooks/useHydra';
import { HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS } from 'constants/hydraTests';

import css from 'styles/components/productdetail/productGallery/productGalleryFeatured.scss';

interface ImageFeaturedProps {
  image: FeaturedImage;
  zoomIsActive: boolean;
  isSelected: boolean;
  showInstructions: boolean;
  setShowInstructions: Dispatch<React.SetStateAction<boolean>>;
  prependedAltText?: string;
  imageHasBadge?: boolean;
  renderDeselected?: boolean;
}

/**
 * PDP Assets gallery featured image container with a11y
 * @returns {FunctionComponent}
 */
const ImageFeatured: FunctionComponent<ImageFeaturedProps> = ({
  image,
  zoomIsActive,
  isSelected,
  showInstructions,
  setShowInstructions,
  prependedAltText,
  renderDeselected = false
}) => {
  if (!renderDeselected && !isSelected) {
    return null;
  }
  if (zoomIsActive) {
    return <ImageZoom image={image} showInstructions={showInstructions} setShowInstructions={setShowInstructions} />;
  }

  const imgSrcSet = image.featured.srcset;
  const webpSrcSet = image.featured.webp?.srcset;

  const aspectRatio = image.featured.aspectRatio ? { aspectRatio: `${image.featured.aspectRatio}` } : undefined;

  // FUTURE: use ../../Image.tsx instead.
  return (
    <picture className={css.imageSizing}>
      {webpSrcSet && <source srcSet={webpSrcSet} type="image/webp" />}
      <img
        style={aspectRatio}
        alt={(prependedAltText && `${prependedAltText} - `) + image.alt}
        src={image.featured.src}
        srcSet={imgSrcSet}
        itemProp="image"
      />
    </picture>
  );
};

const getImagesUrlsToPreload = (productAssets: FeaturedImage[]) =>
  productAssets
    .filter(asset => 'thumbnail' in asset)
    .map(asset => asset.featured?.webp?.srcset?.split(', ').map((srcSet: string) => srcSet.split(' ')[0]) as string[])
    .flatMap(srcSet => srcSet)
    .filter(uri => !!uri);

const preloadFeaturedImages = (productAssets: FeaturedImage[]) => {
  if (ExecutionEnvironment.canUseDOM) {
    const imagesToPreload = getImagesUrlsToPreload(productAssets as FeaturedImage[]);

    preloadImages(imagesToPreload);
  }
};

interface VideoFeaturedProps {
  video: PDPFeaturedVideo;
  isSelected: boolean;
  isAutoPlaying?: boolean;
}

/**
 * PDP Assets gallery featured video container with a11y
 * @returns {FunctionComponent}
 */
const VideoFeatured: FunctionComponent<VideoFeaturedProps> = ({ video, isSelected, isAutoPlaying }) =>
  isSelected && <MelodyVideoPlayer isAutoplaying={!!isAutoPlaying} {...video} />;

interface Props {
  zoomIsActive: boolean;
  setZoomIsActive: Dispatch<React.SetStateAction<boolean>>;
  selectedAsset: number;
  styleId: string;
  productAssets: (FeaturedImage | PDPFeaturedVideo)[];
  setSelectedAsset: Dispatch<React.SetStateAction<number>>;
  productFeaturedRef: PDPCarouselRef;
  productFeaturedCarousel: EmblaCarouselType | undefined;
  productThumbnailsCarousel: EmblaCarouselType | undefined;
  prependedAltText?: string;
  imageHasBadge?: boolean;
}

/**
 * PDP Assets gallery featured container (featured image + featured video)
 * @returns {FunctionComponent}
 */
const ProductGalleryFeatured: FunctionComponent<Props> = ({
  zoomIsActive,
  setZoomIsActive,
  productAssets,
  selectedAsset,
  setSelectedAsset,
  styleId,
  productFeaturedRef,
  productFeaturedCarousel,
  productThumbnailsCarousel,
  prependedAltText,
  imageHasBadge
}) => {
  const { testId } = useMartyContext();

  // Controls the zoom instructions message
  const [showInstructions, setShowInstructions] = useState(true);
  const [startVideo, setStartVideo] = useState(false);
  const isHydraCefi = useInAssignment(HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS);

  // We include `styleId` in the dependencies list of the useEffect call below
  // in order to re-render embla carousel when the product color is switched.
  // This hack is necessary because embla enters an invalid state if the number
  // of elements it needs to render changes.
  // === CAROUSEL CONFIG
  const { width: windowWidth } = useWindowSize();
  useEffect(() => {
    if (windowWidth && productFeaturedCarousel) {
      productFeaturedCarousel.reInit({ loop: true });
    }
  }, [productFeaturedCarousel, styleId, windowWidth, zoomIsActive]);

  // ==== CAROUSEL EVENTS
  const onFeaturedSelect = useCallback(() => {
    // When the asset in the featured section is selected, the correspondent thumbnail is selected as well
    if (productFeaturedCarousel && productThumbnailsCarousel) {
      const selectedScrollSnap = productFeaturedCarousel.selectedScrollSnap();
      setSelectedAsset(selectedScrollSnap);
      productThumbnailsCarousel.scrollTo(selectedScrollSnap);

      !zoomIsActive &&
        track(() => [
          evImageSwipe,
          {
            asset: productAssets[selectedScrollSnap]
          }
        ]);
    }
  }, [productFeaturedCarousel, productThumbnailsCarousel, setSelectedAsset]);
  useEffect(() => {
    productFeaturedCarousel?.on('select', onFeaturedSelect);
    return function cleanup() {
      productFeaturedCarousel?.off('select', onFeaturedSelect);
    };
  }, [onFeaturedSelect, productFeaturedCarousel]);

  useEffect(() => {
    if (isHydraCefi) {
      setStartVideo(false);
    }
  }, [selectedAsset, isHydraCefi]);

  const handleFeaturedClick = (isSelected: boolean, assetType: ImageType | VideoType) => {
    if (isHydraCefi && assetType === 'video') {
      setStartVideo(true);
    } else {
      setZoomIsActive(isSelected);
    }
  };

  const getGalleryChildren = (asset: FeaturedImage | PDPFeaturedVideo, isSelected: boolean) => {
    const videoAsset: PDPFeaturedVideo = asset as PDPFeaturedVideo;
    const videoAssetWithClassName = {
      ...videoAsset,
      slotDetails: {
        ...videoAsset.slotDetails,
        className: cn([videoAsset.slotDetails?.className, css.melodyVideoMask])
      }
    };

    return 'thumbnail' in asset ? (
      <ImageFeatured
        image={asset}
        isSelected={isSelected}
        zoomIsActive={zoomIsActive}
        showInstructions={showInstructions}
        setShowInstructions={setShowInstructions}
        prependedAltText={prependedAltText}
        imageHasBadge={imageHasBadge}
      />
    ) : (
      <VideoFeatured isAutoPlaying={startVideo} video={videoAssetWithClassName} isSelected={isSelected} />
    );
  };

  preloadFeaturedImages(productAssets as FeaturedImage[]);

  return (
    <div id="productFeatured" className={css.productFeatured}>
      <div className={css.carousel} ref={productFeaturedRef}>
        <ul className={css.slides}>
          {productAssets.map((asset, index) => {
            const isSelected = selectedAsset === asset.index;
            return (
              <li className={css.slide} key={index}>
                {!zoomIsActive ? (
                  <button
                    type="button"
                    aria-label={`${asset.alt} Zoom`}
                    aria-current={isSelected}
                    tabIndex={isSelected ? 0 : -1}
                    onClick={() => handleFeaturedClick(isSelected, asset.type)}
                    data-media={asset.type}
                    data-test-id={testId(`${asset.type}Featured`)}
                    className={cn(css.slideFeatured, {
                      [css.zoomMode]: zoomIsActive,
                      [css.startVideoMask]: isHydraCefi ? !startVideo : false // display a mask while video hasn't started
                    })}
                    {...getPDPTrackingPropsFormatted('Zoom-In', `${asset.type}-Click`)}
                  >
                    {getGalleryChildren(asset, isSelected)}
                  </button>
                ) : (
                  getGalleryChildren(asset, isSelected)
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default withErrorBoundary('ProductGalleryFeatured', ProductGalleryFeatured);
