import ExecutionEnvironment from 'exenv';

export interface AreaProps {
  x: number;
  y: number;
  width: number;
  height: number;
}

export function preloadImages(urls: string[]) {
  return new Promise((resolve, reject) => {
    let n = urls.length;
    let images: HTMLImageElement[] | null = null;

    if (!ExecutionEnvironment.canUseDOM) {
      // eslint-disable-next-line no-console
      console.log('Error: preloadImages should only be used clientside.');
    }

    const onload = () => {
      if (!--n) {
        resolve(images);
      }
    };

    images = urls.map((url: string) => {
      const image = new Image();
      image.onload = onload;
      image.onerror = () => reject(`Image could not be loaded '${url}'`);
      image.src = url;
      return image;
    });
  });
}

export const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new window.Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

export default async function getCroppedImage(imageSrc: string, imageType: string, pixelCrop: AreaProps) {
  const image = (await createImage(imageSrc)) as HTMLImageElement;
  const canvas = document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const context = canvas.getContext('2d');
  context?.drawImage(image, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, pixelCrop.width, pixelCrop.height);

  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve({ file: file as Blob, url: URL.createObjectURL(file as Blob), width: pixelCrop.width, height: pixelCrop.height });
    }, imageType);
  });
}
