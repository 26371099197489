import { STYLE_FEED_CARD_CLICK, STYLE_FEED_PAGE_VIEW } from 'constants/amethyst';
import { INFLUENCER_STYLE_FEED } from 'constants/amethystPageTypes';
import { INFLUENCER_CONTENT_TYPE } from 'constants/amethystEnums';

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/StyleFeedPageView.proto
export const evStyleFeedPageView = ({ numContent }) => ({
  [STYLE_FEED_PAGE_VIEW]: {
    numContent,
    sourcePage: INFLUENCER_STYLE_FEED
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/StyleFeedCardClick.proto
export const evStyleFeedCardClick = ({ contentId, contentType, cardIndex }) => ({
  [STYLE_FEED_CARD_CLICK]: {
    contentId,
    contentType: INFLUENCER_CONTENT_TYPE[contentType],
    cardIndex,
    sourcePage: INFLUENCER_STYLE_FEED
  }
});
