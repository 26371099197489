import type { Dispatch, FunctionComponent } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import type { ReactZoomPanPinchHandlers } from 'react-zoom-pan-pinch';
import { TransformComponent, useControls, useTransformEffect } from 'react-zoom-pan-pinch';

import useEvent from 'hooks/useEvent';
import { getPDPTrackingPropsFormatted } from 'helpers/ProductUtils';
import type { FeaturedImage } from 'types/product';
import { AriaLiveTee } from 'components/common/AriaLive';
import { track } from 'apis/amethyst';
import { evFeaturedZoom, evZoomIn, evZoomOut } from 'events/product';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/common/zoom/imageZoomControls.scss';

// these were the magic numbers that defaulted on the original implementation of ImageZoom using
// an older version of the react-pinch-pan-zoom library so on upgrade we maintained those behaviors for consistency
const ZOOM_STEP = 0.4;
export const DEFAULT_ZOOM = 1.49;
interface ZoomControlsProps {
  minScale: number;
  maxScale: number;
  setTransform: ReactZoomPanPinchHandlers['setTransform'];
}

/**
 * PDP Assets gallery zoom controls
 * @returns {FunctionComponent}
 */
const ZoomControls: FunctionComponent<ZoomControlsProps> = ({ minScale, maxScale }) => {
  const { testId } = useMartyContext();
  // Handler for panning through the image using arrow keys
  const { instance, setTransform, zoomIn, zoomOut } = useControls();
  const {
    transformState: { scale }
  } = instance;
  const eventHandler = useCallback(
    (keyboard: KeyboardEvent) => {
      const moveFactor = 25;
      // We need to unbind these inside the callback so they are "live" and not a part of the callback closure
      const {
        transformState: { positionX, positionY, scale }
      } = instance;
      switch (keyboard.code) {
        case 'ArrowUp':
          setTransform(positionX, positionY + moveFactor, scale);
          break;
        case 'ArrowDown':
          setTransform(positionX, positionY - moveFactor, scale);
          break;
        case 'ArrowLeft':
          setTransform(positionX + moveFactor, positionY, scale);
          break;
        case 'ArrowRight':
          setTransform(positionX - moveFactor, positionY, scale);
          break;
        default:
          break;
      }
    },
    [instance, setTransform]
  ) as EventListener;
  useEvent(document, 'keyup', eventHandler);
  // We have to do this since `instance.transformState` is a ref that doesn't cause rerenders when it changes :/
  const [scaleState, setScaleState] = useState(scale);
  useTransformEffect(({ state }) => {
    setScaleState(state.scale);
  });

  const handleZoomOutClick = () => {
    track(() => [evZoomOut, {}]);
    zoomOut(ZOOM_STEP);
  };

  const handleZoomInClick = () => {
    track(() => [evZoomIn, {}]);
    zoomIn(ZOOM_STEP);
  };

  return (
    <div className={css.zoomControls}>
      <button
        className={css.zoomControlButton}
        type="button"
        onClick={handleZoomOutClick}
        aria-label="Zoom Out"
        disabled={scaleState <= minScale}
        data-test-id={testId('zoomOut')}
        {...getPDPTrackingPropsFormatted('Zoom-Out', 'Zoom-Control-Click')}
      />
      <div data-test-id={testId('zoomValue')}>{`${Math.round(scaleState * 100)}%`}</div>
      <button
        className={css.zoomControlButton}
        type="button"
        onClick={handleZoomInClick}
        aria-label="Zoom In"
        disabled={scaleState >= maxScale}
        data-test-id={testId('zoomIn')}
        {...getPDPTrackingPropsFormatted('Zoom-In', 'Zoom-Control-Click')}
      />
    </div>
  );
};

interface ZoomInstructionsProps {
  showInstructions: boolean;
  setShowInstructions: Dispatch<React.SetStateAction<boolean>>;
}

/**
 * PDP Assets gallery zoom instructions banner
 * @returns {FunctionComponent}
 */
const ZoomInstructions: FunctionComponent<ZoomInstructionsProps> = ({ showInstructions, setShowInstructions }) => {
  const { testId } = useMartyContext();
  return showInstructions ? (
    <AriaLiveTee>
      <div className={css.zoomBanner} data-test-id={testId('productGalleryZoomBanner')}>
        <div>Drag image to see more details</div>
        <button
          className={css.bannerButton}
          type="button"
          onClick={() => setShowInstructions(false)}
          aria-label="Close zoom instructions"
          data-test-id={testId('productGalleryZoomBannerClose')}
          {...getPDPTrackingPropsFormatted('Zoom-Instructions-Close', 'Button-Click')}
        />
      </div>
    </AriaLiveTee>
  ) : null;
};

interface ImageZoomControlsProps {
  image: FeaturedImage;
  showInstructions: boolean;
  setShowInstructions: Dispatch<React.SetStateAction<boolean>>;
  minScale: number;
  maxScale: number;
}

/**
 * PDP Assets gallery zoomed image
 * @returns {FunctionComponent}
 */
const ImageZoomControls: FunctionComponent<ImageZoomControlsProps> = ({ minScale, maxScale, image, showInstructions, setShowInstructions }) => {
  const { testId } = useMartyContext();
  const { zoomIn, resetTransform, centerView, setTransform } = useControls();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { detail } = event;
    const DOUBLE_CLICK = 2;

    if (detail === DOUBLE_CLICK) {
      trackZoomEvent();
    }
  };

  const trackZoomEvent = () => {
    const {
      thumbnail: { src },
      alt
    } = image;
    track(() => [evFeaturedZoom, { src, alt }]);
  };

  const handleImageLoad = () => {
    if (!isImageLoaded) {
      trackZoomEvent();
      setIsImageLoaded(true);
    }
  };

  // if the image changes reset the zoom
  useEffect(() => {
    if (isImageLoaded) {
      centerView(DEFAULT_ZOOM);
    }
  }, [isImageLoaded, resetTransform, zoomIn, image]);

  return (
    <>
      <ZoomInstructions showInstructions={showInstructions} setShowInstructions={setShowInstructions} />
      <ZoomControls minScale={minScale} maxScale={maxScale} setTransform={setTransform} />
      <TransformComponent wrapperClass={css.zoom} contentClass={css.zoomContent}>
        <button type="button" className={css.imageButton} onClick={handleImageButtonClick}>
          <picture>
            {image.zoom.webp && <source srcSet={`${image.zoom.webp.src} 1x, ${image.zoom.webp.retinaSrc}`} type="image/webp" />}
            <source srcSet={`${image.zoom.src} 1x, ${image.zoom.retinaSrc}`} type="image/jpeg" />
            <img
              data-test-id={testId('zoomedImg')}
              alt={`${image.alt} Zoom`}
              src={image.zoom.src}
              srcSet={image.zoom.retinaSrc}
              onLoad={handleImageLoad}
            />
          </picture>
        </button>
      </TransformComponent>
    </>
  );
};

export default ImageZoomControls;
