import React from 'react';

import type { ProductStyle } from 'types/cloudCatalog';
import ProductUtils from 'helpers/ProductUtils';

import css from 'styles/components/influencer/shoppablePosts/productPrice.scss';

interface ProductPriceProps {
  productStyle: Pick<ProductStyle, 'price' | 'originalPrice'>;
}

export const ProductPrice = ({ productStyle }: ProductPriceProps) => {
  const { price, originalPrice } = productStyle;
  const showDiscount = ProductUtils.isStyleOnSale(productStyle);

  return (
    <div className={css.priceContainer}>
      {showDiscount ? (
        <>
          <div className={css.discountedPrice}>{price}</div>
          <div className={css.originalPrice}>{originalPrice}</div>
        </>
      ) : (
        <div className={css.productPrice}>{price}</div>
      )}
    </div>
  );
};

export default ProductPrice;
