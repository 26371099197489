import React from 'react';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import { formatDropdownNames } from 'helpers/ShoppablePostUtils';
import { ProductNotifyModal } from 'components/productdetail/asyncProductPageModals';
import type { SizePickerProps } from 'components/influencer/shoppablePosts/SizePickerWrapper';
import type { SelectMenuOption } from 'types/influencer';

import css from 'styles/components/outfitRecos/sizePickerV2.scss';

export const SizePickerV2 = ({
  isAddToCartEnabled,
  onAddToCart,
  colorId,
  productId,
  selectedDimensions,
  dimensionsSet,
  dimensionIdToName,
  dimensionIdToUnitId,
  valuesSet,
  valueIdToName,
  inStockValues,
  lowStockMessage,
  isSelectedDimensionsOOS,
  showOOSModal,
  openProductNotifyModal,
  onProductNotifyModalClose,
  createDropdown
}: SizePickerProps) => {
  const { testId } = useMartyContext();
  return (
    <div className={css.sizeChooserSection} data-test-id={testId('SizePickerV2')}>
      <div className={css.dimensionSection}>
        {dimensionsSet.map((dimension, index) => {
          const dimensionName = dimensionIdToName[dimension];
          const dimensionValues = valuesSet[dimension]![dimensionIdToUnitId[dimension]!]!;
          const dropdownOptions: SelectMenuOption[] = formatDropdownNames(dimensionValues, valueIdToName, inStockValues[dimension]);
          return (
            <div key={dimension} className={css.sizeChooserControlWrapper}>
              {createDropdown(dropdownOptions, dimensionName!, dimension, index)}
            </div>
          );
        })}
      </div>
      <div className={css.addToCartWrapper}>
        {lowStockMessage && <span className={css.stockMessage}>{lowStockMessage}</span>}
        <button
          onClick={isSelectedDimensionsOOS ? openProductNotifyModal : onAddToCart}
          type="button"
          tabIndex={!isAddToCartEnabled ? -1 : 0}
          className={cn(css.addToCart, { [css.disableAddToCart]: !isAddToCartEnabled })}
          data-test-id={testId('addToBag')}
        >
          {isSelectedDimensionsOOS ? 'Out Of Stock' : 'Add To Bag'}
        </button>
      </div>
      {showOOSModal && (
        <ProductNotifyModal
          isOpen={showOOSModal}
          onClose={onProductNotifyModalClose}
          productId={productId}
          colorId={colorId}
          selectedSizing={selectedDimensions}
        />
      )}
    </div>
  );
};

export default SizePickerV2;
