import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { handleGetInfluencerDetailsByIdentifier } from 'actions/influencer/influencer';
import { fetchAllInfluencerContent } from 'actions/influencer/influencerContent';
import useEffectOnce from 'hooks/useEffectOnce';
import { clearProductStyleDetails } from 'actions/influencer/shoppablePost';
import MelodyModal from 'components/common/MelodyModal';
import MartyLink from 'components/common/MartyLink';
import type { InfluencerShoppablePost, NonEmptyArray, ProductMetadata, ProductStyleDetails } from 'types/influencer';
import { CustomerView } from 'components/influencer/shoppablePosts/CustomerView';
import { PageLoader } from 'components/Loader';
import { evShoppablePostMyCartBtnClick, evShoppablePostQuickView } from 'events/influencerShoppablePost';
import { track } from 'apis/amethyst';
import { INFLUENCER_SHOPPABLE_POSTS_PAGE } from 'constants/amethystPageTypes';
import { IDENTIFIER_TYPE_CUSTOMER_ID, SHOP_THE_LOOK_STYLE_FEED_URL } from 'constants/influencerPages';
import { TITLE_ID } from 'constants/shoppablePosts';
import { pluralize } from 'helpers/index';
import { toUSD } from 'helpers/NumberFormats';
import type { AppDispatch } from 'entrypoints/bootstrapOnClient';
import type { AppState } from 'types/app';

import css from 'styles/components/influencer/shoppablePosts/quickViewModal.scss';

enum ModalStates {
  LOADING = 'LOADING',
  LOADING_COMPLETED = 'LOADING_COMPLETED'
}

interface Props {
  mediaSource: string;
  shoppablePost: InfluencerShoppablePost;
  productStyleDetails: ProductStyleDetails;
  taggedProducts: NonEmptyArray<ProductMetadata>;
  onModalClose: () => void;
  allowSharing: boolean;
  showCart: boolean;
  showContinueBtn: boolean;
  showShopMoreLooksLink: boolean;
  shouldCleanupOnUnmount?: boolean;
  customerViewClassName?: string;
  influencerId?: string;
}

interface ProductIdentifiers {
  styleId: string;
  productId: number;
  supplementalData?: { isInStock: boolean };
}

export const QuickViewModal = ({
  mediaSource,
  shoppablePost,
  productStyleDetails,
  taggedProducts,
  onModalClose,
  allowSharing,
  showCart,
  showContinueBtn,
  showShopMoreLooksLink,
  shouldCleanupOnUnmount,
  customerViewClassName,
  influencerId
}: Props) => {
  const [arrAllProductDetailsFetched, setArrAllProductDetailsFetched] = useState(
    taggedProducts.some(
      ({ styleId }) => Object.keys(productStyleDetails).includes(styleId) && Object.keys(productStyleDetails[styleId]?.productData!).length
    )
  );

  const isComponentDataFetched = shoppablePost && arrAllProductDetailsFetched;
  const [modalState, setModalState] = useState(isComponentDataFetched ? ModalStates.LOADING_COMPLETED : ModalStates.LOADING);
  const cart = useSelector((state: AppState) => state.cart);
  const influencerDetails = useSelector((state: AppState) => state.influencer);
  const url = useSelector((state: AppState) => state.url);
  const influencerContent = useSelector((state: AppState) => state.influencerContent);
  const {
    cartCount,
    cartObj: { subtotal }
  } = cart;

  const { profileImage = { imageId: null } } = influencerContent;
  const { host } = url;
  const dispatch: AppDispatch = useDispatch();

  useEffectOnce(
    () =>
      // on component unmounting
      function cleanup() {
        shouldCleanupOnUnmount && dispatch(clearProductStyleDetails());
      }
  );

  useEffect(() => {
    setArrAllProductDetailsFetched(taggedProducts.some(({ styleId }) => Object.keys(productStyleDetails).includes(styleId)));
  }, [taggedProducts.length, Object.keys(productStyleDetails).length]);

  useEffect(() => {
    if (isComponentDataFetched && modalState === ModalStates.LOADING) {
      setModalState(ModalStates.LOADING_COMPLETED);
    }
  }, [isComponentDataFetched]);

  useEffect(() => {
    if (influencerId) {
      dispatch(fetchAllInfluencerContent(influencerId, false));
      dispatch(handleGetInfluencerDetailsByIdentifier(influencerId, IDENTIFIER_TYPE_CUSTOMER_ID, host));
    }
  }, [influencerId]);

  useEffect(() => {
    if (modalState === ModalStates.LOADING_COMPLETED && taggedProducts.every(({ styleId }) => Object.keys(productStyleDetails).includes(styleId))) {
      const productIdentifiers: ProductIdentifiers[] = [];

      taggedProducts.map((product: ProductMetadata) => {
        productIdentifiers.push({
          styleId: product.styleId,
          productId: productStyleDetails[product.styleId]?.productId!,
          supplementalData: {
            isInStock: productStyleDetails[product.styleId]?.productData.detail?.oos === false
          }
        });
      });

      track(() => [
        evShoppablePostQuickView,
        {
          shoppablePostId: shoppablePost.postId,
          shoppableContentType: shoppablePost.postType,
          taggedProducts: productIdentifiers,
          sourcePage: INFLUENCER_SHOPPABLE_POSTS_PAGE
        }
      ]);
    }
  }, [modalState, productStyleDetails]);

  const fireMyCartButtonClickEvent = () => {
    track(() => [
      evShoppablePostMyCartBtnClick,
      {
        shoppablePostId: shoppablePost.postId,
        shoppableContentType: shoppablePost.postType,
        sourcePage: INFLUENCER_SHOPPABLE_POSTS_PAGE
      }
    ]);
  };

  const cartBtn = (
    <MartyLink
      to="/cart"
      onClick={fireMyCartButtonClickEvent}
      aria-label={`Go to my bag. ${cartCount ? `${cartCount} ${pluralize('item', cartCount)} in bag.` : 'Bag empty.'}`}
      className={css.cartBtn}
    >
      {cartCount ? `${cartCount} ${pluralize('Item', cartCount)} in bag` : 'My Bag'}
    </MartyLink>
  );

  const customerViewMarkup = (
    <>
      <CustomerView
        mediaSource={mediaSource}
        shoppablePost={shoppablePost}
        productStyleDetails={productStyleDetails}
        taggedProducts={taggedProducts}
        allowSharing={allowSharing}
        showCart={false}
        cartBtn={cartBtn}
        isQuickView={true}
        className={customerViewClassName}
        profileImage={profileImage}
        influencerDetails={influencerDetails}
      />
      <div className={css.footer}>
        {showContinueBtn && (
          <button onClick={onModalClose} className={css.continueBtn} type="button">
            Continue Shopping
          </button>
        )}
        {showShopMoreLooksLink && (
          <MartyLink to={SHOP_THE_LOOK_STYLE_FEED_URL} aria-label="Shop More Looks Link" className={css.shopMoreLooksLink}>
            Shop More Looks
          </MartyLink>
        )}
        {showCart && (
          <div className={css.cartSummary}>
            <span className={css.cartTotal}>
              Bag Subtotal ({cartCount} {pluralize('Item', cartCount)}) {subtotal && toUSD(subtotal.amount)}
            </span>
            {cartBtn}
          </div>
        )}
      </div>
    </>
  );

  const ariaProps = { labelledby: TITLE_ID, describedby: TITLE_ID };

  return (
    <MelodyModal isOpen={true} className={css.modalContainer} overlayClassName={css.modalOverlay} onRequestClose={onModalClose} aria={ariaProps}>
      {
        {
          [ModalStates.LOADING]: <PageLoader centered={true} />,
          [ModalStates.LOADING_COMPLETED]: customerViewMarkup
        }[modalState]
      }
    </MelodyModal>
  );
};

export default QuickViewModal;
