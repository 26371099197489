import type { Dispatch, RefObject, SetStateAction } from 'react';
import React, { useEffect, useState } from 'react';
import { useSelect } from 'downshift';

import { cn } from 'helpers/classnames';
import { formatDimensionName, getInStockValues } from 'helpers/ShoppablePostUtils';
import ProductUtils from 'helpers/ProductUtils';
import type { DimensionId, MapSomeDimensionIdTo, ProductStyle, ValueIDToName } from 'types/cloudCatalog';
import type { InStockMap, SelectedDimensions, SelectMenuOption } from 'types/influencer';
import type { FormattedProductData, FormattedProductSizing } from 'reducers/detail/productDetail';
import SizePicker from 'components/influencer/shoppablePosts/SizePicker';
import SizePickerV2 from 'components/outfitRecos/SizePickerV2';

import css from 'styles/components/influencer/shoppablePosts/sizePickerWrapper.scss';

export interface SizePickerProps {
  isAddToCartEnabled: boolean;
  onAddToCart: (e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => void;
  colorId: string;
  productId: string;
  selectedDimensions: SelectedDimensions;
  dimensionsSet: DimensionId[];
  dimensionsSetLength: number;
  dimensionIdToName: MapSomeDimensionIdTo<string>;
  dimensionIdToUnitId: MapSomeDimensionIdTo<string>;
  valuesSet: MapSomeDimensionIdTo<Record<string, string[]>>;
  valueIdToName: Record<string, ValueIDToName>;
  inStockValues: InStockMap;
  lowStockMessage: string;
  isSelectedDimensionsOOS: boolean;
  showOOSModal: boolean;
  openProductNotifyModal: () => void;
  onProductNotifyModalClose: () => void;
  createDropdown: any;
}

interface SizePickerWrapperProps {
  setSelectedStockId: Dispatch<SetStateAction<string | undefined>>;
  onAddToCart: (e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => void;
  sizing: FormattedProductSizing;
  colorId: string;
  selectedStyle: ProductStyle | undefined;
  productData: FormattedProductData;
  productId: string;
  resetProductNotifyState: () => void;
  useV2?: boolean;
  sourcePage?: string;
}

const getOptionCSSClasses = (isHighLighted: boolean, isSelected: boolean, outOfStock: boolean) =>
  cn(css.dropdownOption, {
    [css.outOfStockOption]: outOfStock,
    [css.selectedOption]: isSelected,
    [css.highlightedOption]: !isSelected && isHighLighted
  });

export const SizePickerWrapper = ({
  setSelectedStockId,
  onAddToCart,
  sizing,
  colorId,
  selectedStyle,
  productData,
  productId,
  resetProductNotifyState,
  useV2 = false,
  sourcePage = ''
}: SizePickerWrapperProps) => {
  const { valuesSet, valueIdToName, dimensionsSet = [], dimensionIdToUnitId, dimensionIdToName, stockData, airplaneCache } = sizing || {};
  const dimensionsSetLength = dimensionsSet.length;

  const [selectedDimensions, setSelectedDimensions] = useState<SelectedDimensions>({
    ...productData?.selectedSizing
  } as SelectedDimensions);
  const [inStockValues, setInStockValues] = useState<InStockMap>({});
  const [isAddToCartEnabled, setIsAddToCartEnabled] = useState(Object.keys(selectedDimensions).length === dimensionsSetLength);
  const [isSelectedDimensionsOOS, setIsSelectedDimensionsOOS] = useState(false);
  const [showOOSModal, setShowOOSModal] = useState(false);
  const [lowStockMessage, setLowStockMessage] = useState('');
  const selectorRefs = React.useRef<RefObject<HTMLSelectElement>[]>(dimensionsSet.map(() => React.createRef()));

  useEffect(() => {
    selectorRefs.current.map(ref => {
      if (ref && ref.current) {
        ref.current!.selectedIndex = 0;
      }
    });
    // reset the local state
    const selectedDimensions = { ...productData?.selectedSizing } as SelectedDimensions;
    setSelectedDimensions(selectedDimensions);
  }, [selectedStyle]);

  useEffect(() => {
    if (Object.keys(selectedDimensions).length === dimensionsSetLength && stockData) {
      const stock = ProductUtils.getStockBySize(stockData, colorId, selectedDimensions);
      setSelectedStockId(stock?.id);
      setIsAddToCartEnabled(true);
      setIsSelectedDimensionsOOS(!stock || !parseInt(stock.onHand));
      setLowStockMessage(stock && parseInt(stock.onHand) < 10 ? `Only ${parseInt(stock.onHand)} left in stock!` : '');
    } else {
      setSelectedStockId(undefined);
      setIsAddToCartEnabled(false);
      setIsSelectedDimensionsOOS(false);
      setLowStockMessage('');
    }
  }, [selectedDimensions]);

  useEffect(() => {
    setInStockValues(getInStockValues(selectedDimensions, dimensionsSet, stockData, colorId));
  }, [valuesSet, stockData, selectedDimensions, colorId]);

  const selectSizingOption = (value: string, dimension: string, index: number) => {
    if (value === '') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [dimension]: _, ...dimensionRemoved } = selectedDimensions;
      setSelectedDimensions(dimensionRemoved);
    } else {
      const newSelectedDimensions = {
        ...selectedDimensions,
        [dimension]: value
      };
      setSelectedDimensions(newSelectedDimensions);

      const options = sizing.allUnits[index]!.values;
      const stock = ProductUtils.getStockBySize(stockData, colorId, newSelectedDimensions);
      setShowOOSModal(Object.keys(newSelectedDimensions).length === dimensionsSetLength && !(stock && parseInt(stock.onHand)));

      ProductUtils.generateProductDimensionSelectedAmethystEvent(
        dimensionIdToName[dimension],
        valueIdToName[value]!.value,
        sourcePage,
        options!,
        newSelectedDimensions,
        dimension,
        value,
        sizing,
        colorId,
        selectedStyle!
      );
    }
  };

  const openProductNotifyModal = () => {
    setShowOOSModal(true);
  };

  const onProductNotifyModalClose = () => {
    setShowOOSModal(false);
    resetProductNotifyState();
  };

  const CreateDropdown = (dropdownOptions: SelectMenuOption[], dimensionName: string, dimension: string, index: number) => {
    const getDropdownValue = (item: SelectMenuOption | null) => (item ? item.value : '');
    const {
      isOpen,
      selectedItem,
      getLabelProps,
      getToggleButtonProps,
      getMenuProps,
      highlightedIndex,
      getItemProps,
      reset: resetSelectedMenuState
    } = useSelect<SelectMenuOption>({
      items: dropdownOptions,
      itemToString: getDropdownValue,
      defaultSelectedItem: dropdownOptions.length === 1 ? dropdownOptions[0] : undefined,
      onSelectedItemChange: selection => selection.selectedItem && selectSizingOption(selection.selectedItem.value, dimension, index),
      getA11ySelectionMessage: options => (options?.selectedItem?.label ? `${options.selectedItem.label} has been selected.` : 'Selected')
    });

    useEffect(() => {
      // reset selected item from dimensions upon switching styles
      resetSelectedMenuState();
    }, [selectedStyle]);

    return (
      <div>
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label {...getLabelProps()}>{formatDimensionName(dimensionName!, airplaneCache!)}</label>
        <button type="button" className={css.dropdownSelect} {...getToggleButtonProps()}>
          {selectedItem ? selectedItem.label : `Select ${dimensionName}: `}
        </button>
        {isOpen && (
          <ul className={css.dropdownList} {...getMenuProps()}>
            {dropdownOptions.map((item, index) => {
              const { value, label, isInStock } = item;
              const isSelected = item.value === selectedItem?.value;
              const itemProps = {
                ...getItemProps({ item, index }),
                'aria-label': `${label}${!isInStock ? ' - Out Of Stock' : ''}`
              };
              return (
                <li
                  key={`${dimensionName}-${value}`}
                  className={getOptionCSSClasses(index === highlightedIndex, isSelected, !isInStock)}
                  {...itemProps}
                >
                  {label}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  };

  const sizePickerProps = {
    isAddToCartEnabled,
    onAddToCart,
    colorId,
    productId,
    selectedDimensions,
    dimensionsSet,
    dimensionsSetLength,
    dimensionIdToName,
    dimensionIdToUnitId,
    valuesSet,
    valueIdToName,
    inStockValues,
    lowStockMessage,
    isSelectedDimensionsOOS,
    showOOSModal,
    openProductNotifyModal,
    onProductNotifyModalClose,
    createDropdown: CreateDropdown
  };

  return useV2 ? <SizePickerV2 {...sizePickerProps} /> : <SizePicker {...sizePickerProps} />;
};

export default SizePickerWrapper;
