import type { Dispatch, FunctionComponent } from 'react';
import React, { useState } from 'react';

import MelodyModal from 'components/common/MelodyModal';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';

import css from 'styles/components/common/zoom/imageZoomController.scss';

export interface Props {
  children: (zoomIsActive: boolean, setZoomIsActive: Dispatch<React.SetStateAction<boolean>>) => React.ReactNode;
  isDefaultZoom?: boolean;
  onZoomClose?: () => void;
}

/**
 * Shows zoom in a Melody Modal
 * @returns {FunctionComponent}
 */
const ImageZoomController: FunctionComponent<Props> = ({ isDefaultZoom = false, onZoomClose = () => {}, children }) => {
  // Controls the zoom mode
  const [zoomIsActive, setZoomIsActive] = useState(isDefaultZoom);

  const onModalClose = () => {
    setZoomIsActive(false);
    onZoomClose();
  };

  return (
    // When zoom is active, the children get rendered inside of a fullscreen modal
    zoomIsActive ? (
      <MelodyModal isOpen={true} className={css.zoomModal} buttonTestId="productGalleryZoomClose" onRequestClose={onModalClose}>
        {children(zoomIsActive, setZoomIsActive)}
      </MelodyModal>
    ) : (
      <>{children(zoomIsActive, setZoomIsActive)}</>
    )
  );
};

export default withErrorBoundary('ImageZoomController', ImageZoomController);
