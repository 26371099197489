import type { Dispatch, FunctionComponent } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';

import { cn } from 'helpers/classnames';
import useWindowSize from 'hooks/useWindowSize';
import useMartyContext from 'hooks/useMartyContext';
import type { ProductStyle, ProductVideo } from 'types/cloudCatalog';
import type { FeaturedImage, PDPFeaturedVideo } from 'types/product';
import type { FormattedProductBundle } from 'reducers/detail/productDetail';
import { filterProductPhotos, getProductImagesFormatted, getProductVideoFormatted, SIX_SHOT_PHOTO_PRODUCTS } from 'helpers/ProductUtils';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import ProductGalleryFeatured from 'components/productdetail/productGallery/ProductGalleryFeatured';
import ProductGalleryThumbnails from 'components/productdetail/productGallery/ProductGalleryThumbnails';
import ImageZoomController from 'components/common/zoom/ImageZoomController';
import { HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS } from 'constants/hydraTests';
import { useInAssignment } from 'hooks/useHydra';

import css from 'styles/components/productdetail/productGallery/productGallery.scss';

interface ProductGalleryGroupProps {
  zoomIsActive: boolean;
  setZoomIsActive: Dispatch<React.SetStateAction<boolean>>;
  selectedAsset: number;
  setSelectedAsset: Dispatch<React.SetStateAction<number>>;
  isVideoSelected?: (productAssetType: boolean) => void;
  styleId: string;
  productAssets: (FeaturedImage | PDPFeaturedVideo)[];
  hasHorizontalThumbnails?: boolean;
  prependedAltText?: string;
  imageHasBadge?: boolean;
}

/**
 * PDP Assets gallery  group
 * (needed to created a separate component so hooks wouldn't cause linting issues)
 * @returns {FunctionComponent}
 */
const ProductGalleryGroup: FunctionComponent<ProductGalleryGroupProps> = ({
  zoomIsActive,
  setZoomIsActive,
  selectedAsset,
  setSelectedAsset,
  isVideoSelected,
  styleId,
  productAssets,
  hasHorizontalThumbnails,
  prependedAltText,
  imageHasBadge
}) => {
  const [prevStyleId, setPrevStyleId] = useState(styleId);

  // Pointers to the thumbnails/featured carousel
  const [baseConfig] = useState({ speed: 100, startIndex: selectedAsset });
  const [productFeaturedRef, productFeaturedCarousel] = useEmblaCarousel(baseConfig); // [containerRef, carouselApi]
  const [productThumbnailsRef, productThumbnailsCarousel] = useEmblaCarousel(baseConfig); // [containerRef, carouselApi]

  // If the `selectedAsset` is an index outside the range of `productAssets`
  // (e.g., because we switched to a color with less product angle photos) we
  // reset `selectedAsset` to 0.
  useEffect(() => {
    if (selectedAsset > productAssets.length - 1) {
      setSelectedAsset(0);
    }
  }, [selectedAsset, productAssets.length, setSelectedAsset]);

  useEffect(() => {
    if (styleId !== prevStyleId) {
      setSelectedAsset(0);
      productFeaturedCarousel?.scrollTo(0);
      setPrevStyleId(styleId);
    }
    // TODO remove this eslint exception when https://github01.zappos.net/mweb/marty/issues/17305 is addressed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [styleId]);

  useEffect(() => {
    productFeaturedCarousel?.scrollTo(selectedAsset);
    // TODO remove this eslint exception when https://github01.zappos.net/mweb/marty/issues/17305 is addressed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomIsActive]);

  useEffect(() => {
    const productAssetType = productAssets[selectedAsset]?.type;
    isVideoSelected && isVideoSelected(productAssetType === 'video');
  }, [selectedAsset]);

  return (
    <>
      <ProductGalleryThumbnails
        productAssets={productAssets}
        // State props
        zoomIsActive={zoomIsActive}
        selectedAsset={selectedAsset}
        // Carousel ref props
        productThumbnailsRef={productThumbnailsRef}
        productThumbnailsCarousel={productThumbnailsCarousel}
        productFeaturedCarousel={productFeaturedCarousel}
        hasHorizontalThumbnails={hasHorizontalThumbnails}
        prependedAltText={prependedAltText}
      />
      <ProductGalleryFeatured
        productAssets={productAssets}
        // State props
        zoomIsActive={zoomIsActive}
        setZoomIsActive={setZoomIsActive}
        selectedAsset={selectedAsset}
        setSelectedAsset={setSelectedAsset}
        styleId={styleId}
        imageHasBadge={imageHasBadge}
        // Carousel ref props
        productFeaturedRef={productFeaturedRef}
        productFeaturedCarousel={productFeaturedCarousel}
        productThumbnailsCarousel={productThumbnailsCarousel}
        prependedAltText={prependedAltText}
      />
    </>
  );
};

interface Props {
  style: ProductStyle;
  product: FormattedProductBundle;
  productVideos: ProductVideo[];
  isYouTubeVideo: boolean;
  youtubeSrc: string | undefined;
  inQuickShop?: boolean;
  isVideoSelected?: (productAssetType: boolean) => void;
  hasHorizontalThumbnails?: boolean;
  imageChildren?: React.ReactNode;
  imageHasBadge?: boolean;
  isHydraPhotoAngles?: boolean;
  isDefaultZoom?: boolean;
  onZoomClose?: () => void;
}

/**
 * PDP Assets gallery container (carousel of images + videos with thumbnails + zoom controller)
 * @returns {FunctionComponent}
 */
const ProductGallery: FunctionComponent<Props> = ({
  style,
  product,
  productVideos,
  isYouTubeVideo,
  youtubeSrc,
  inQuickShop,
  isVideoSelected,
  hasHorizontalThumbnails,
  imageChildren,
  imageHasBadge,
  isHydraPhotoAngles,
  isDefaultZoom = false,
  onZoomClose
}) => {
  const { testId } = useMartyContext();

  // Controls the current selected gallery asset
  const [selectedAsset, setSelectedAsset] = useState(0);

  // Controls the gallery height (this is responsible for the sticky functionality not relying on css 'vh')
  const productGalleryRef = useRef<HTMLElement>(null);
  const { height: windowHeight } = useWindowSize();
  const { productName, brandName, productId } = product;
  const { styleId } = style;
  const isHydraCefi = useInAssignment(HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS);

  useEffect(() => {
    if (windowHeight && productGalleryRef.current !== null) {
      productGalleryRef.current.style.height = `${windowHeight}px`;
    }
  }, [windowHeight, productGalleryRef]);

  // Creates a gallery of combined assets (featured images + featured videos)
  const productAssets: (FeaturedImage | PDPFeaturedVideo)[] = [];

  // Featured Images
  const productImages: FeaturedImage[] = getProductImagesFormatted(style?.images, product?.defaultProductType);

  const filteredProductImages: FeaturedImage[] = isHydraPhotoAngles
    ? filterProductPhotos(productImages, { productId, styleId }, SIX_SHOT_PHOTO_PRODUCTS)
    : productImages;

  if (filteredProductImages) {
    productAssets.push(...filteredProductImages);
  }

  // Featured Videos
  const productVideo: PDPFeaturedVideo | undefined = getProductVideoFormatted(
    productVideos,
    product.productId,
    productAssets.length,
    isYouTubeVideo,
    youtubeSrc,
    isHydraCefi
  );
  if (productVideo) {
    productAssets.push(productVideo);
  }

  return (
    <section
      ref={productGalleryRef}
      aria-label="Product gallery"
      className={cn(css.productGallery, { [css.inQuickShop]: inQuickShop })}
      data-test-id={testId('productGalleryContainer')}
    >
      <ImageZoomController isDefaultZoom={isDefaultZoom} onZoomClose={onZoomClose}>
        {(zoomIsActive, setZoomIsActive) => (
          <>
            <ProductGalleryGroup
              zoomIsActive={zoomIsActive}
              setZoomIsActive={setZoomIsActive}
              selectedAsset={selectedAsset}
              setSelectedAsset={setSelectedAsset}
              isVideoSelected={isVideoSelected}
              styleId={style.styleId}
              productAssets={productAssets}
              hasHorizontalThumbnails={hasHorizontalThumbnails}
              prependedAltText={`${brandName} ${productName}`}
              imageHasBadge={imageHasBadge}
            />
          </>
        )}
      </ImageZoomController>
      {imageChildren}
    </section>
  );
};

export default withErrorBoundary('ProductGallery', ProductGallery);
