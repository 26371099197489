import type { RefObject, SetStateAction } from 'react';
import React, { useEffect, useState } from 'react';
import ExecutionEnvironment from 'exenv';
import type { ConnectedProps } from 'react-redux';
import { connect, useSelector } from 'react-redux';

import { cn } from 'helpers/classnames';
import { changeQuantity } from 'actions/cart';
import Rating from 'components/Rating';
import type { ProductRating, ProductStyle } from 'types/cloudCatalog';
import { constructMSAImageUrl } from 'helpers/index';
import { commonDefaultColorSwatchParams, generateLabelForProduct, translateToProducts } from 'helpers/ShoppablePostUtils';
import ProductPrice from 'components/influencer/shoppablePosts/ProductPrice';
import SizePickerWrapper from 'components/influencer/shoppablePosts/SizePickerWrapper';
import Hearts from 'components/common/Hearts';
import { heartProduct, toggleHeartingLoginModal, unHeartProduct } from 'actions/hearts';
import { toggleProductNotifyModal } from 'actions/productdetail/sharing';
import { translateCartError } from 'apis/mafia';
import type { ProductDetails } from 'types/influencer';
import { ProductCardState } from 'types/influencer';
import { fetchProductStyleDetails } from 'actions/influencer/shoppablePost';
import { SmallLoader } from 'components/Loader';
import { SOLD_LAST_ITEM_IN_THIS_SIZE, YOU_JUST_MISSED_IT } from 'constants/influencerMessages';
import { track } from 'apis/amethyst';
import { evAddToCart } from 'events/cart';
import type { AppState } from 'types/app';
import { PRODUCT_INTERACTION_TYPE } from 'constants/amethystEnums';
import { INFLUENCER_SHOPPABLE_POSTS_PAGE } from 'constants/amethystPageTypes';
import MartyLink from 'components/common/MartyLink';
import { ColorSwatches } from 'components/search/ColorSwatches';
import { getProductWithRelatedStyles } from 'helpers/RecoUtils';
import { Accordion, AccordionItem } from 'components/common/MelodyAccordion';
import { MSA_IMAGE_DIMENSIONS } from 'constants/shoppablePosts';
import type { CartError, CartResponse } from 'types/mafia';
import type { Product } from 'constants/searchTypes';
import GenericSizeBiasReco from 'components/productdetail/stylepicker/GenericSizeBiasReco';

import css from 'styles/components/influencer/shoppablePosts/customerViewProductCard.scss';

interface OwnProps {
  styleId: string;
  focusedStyle: string;
  setFocusedStyle: (input: SetStateAction<string>) => void;
  setZoomedStyleId: (input: SetStateAction<[string, string]>) => void;
  fireShoppablePostProductInteractionEvent: (interactionType: string, styleId: string, productId: number, colorId: string) => void;
  fireShoppablePostProductClickThroughEvent: (styleId: string, productId: number) => void;
  productStyleDetails: ProductDetails;
  productDetailUrl?: string;
  merchantId: string;
  productId: number;
  scrollRef: RefObject<HTMLDivElement>;
  isQuickView: boolean;
  isSmoothScrollSupportedByBrowser: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = OwnProps & PropsFromRedux;

const NO_TOTAL_REVIEWS = '0';

export const CustomerViewProductCard = (props: Props) => {
  const {
    styleId,
    productId,
    focusedStyle,
    setFocusedStyle,
    setZoomedStyleId,
    productStyleDetails,
    changeQuantity,
    productDetailUrl = '',
    merchantId = '',
    fetchProductStyleDetails,
    toggleProductNotifyModal,
    heartProduct,
    toggleHeartingLoginModal,
    unHeartProduct,
    isCustomer,
    fireShoppablePostProductInteractionEvent
  } = props;

  const { productData } = productStyleDetails;

  const [selectedStockId, setSelectedStockId] = useState<string | undefined>();
  const [productCardState, setProductCardState] = useState<ProductCardState>(ProductCardState.SIZE_PICK);
  const [currentStyleId, setCurrentStyleId] = useState(styleId);
  const cardRef = React.useRef<HTMLDivElement>(null);

  const recoProductRelations = useSelector((state: AppState) => state.products.recoProductRelations);

  useEffect(() => {
    if (focusedStyle === styleId) {
      cardRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [focusedStyle]);

  if (!Object.keys(productData).length) {
    return null;
  }

  const possibleStarRatings: number[] = [5, 4, 3, 2, 1];
  const style = productData?.detail?.styles?.filter((styleDetails: ProductStyle) => styleDetails.styleId === currentStyleId)[0]!;
  const { colorId, color, isNew, onSale, originalPrice, price, percentOff, productUrl } = style || {};

  const formattedStyles: { style: ProductStyle; index: number }[] = [];
  productData?.detail?.styles?.forEach((style: ProductStyle, index: number) => {
    const { styleId } = style;
    formattedStyles[parseInt(styleId)] = { style, index };
  });

  const {
    detail: { brandName, productName, productRating, reviewCount, sizing, reviewSummary },
    seoProductUrl,
    genericSizeBiases
  } = productData;

  const { totalReviews, overallRating, maxArchRatingPercentage, maxSizeRatingPercentage, maxWidthRatingPercentage, hasFitRatings } =
    reviewSummary || {};
  const showRatingAccordion = totalReviews !== NO_TOTAL_REVIEWS && hasFitRatings === 'true';

  const productWithRelatedStyles = getProductWithRelatedStyles(currentStyleId, recoProductRelations);
  const { relatedStyles } = productWithRelatedStyles;
  const relatedSwatchData: Product[] = translateToProducts(relatedStyles || []);

  const onAddToCart = (e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setProductCardState(ProductCardState.ADD_TO_CART_IN_PROGRESS);
    if (selectedStockId) {
      changeQuantity({ items: [{ stockId: selectedStockId, quantity: 1, quantityAddition: true, merchantId }] }, { firePixel: true }).then(
        (response: CartResponse | CartError) => {
          const error = translateCartError(response);
          if (error) {
            setProductCardState(ProductCardState.ADD_TO_CART_FAIL);
            // refetch cloud catalog for this product
            const fetchOpts = {
              includeSizing: true,
              fetchOnlyListedProducts: true,
              includeOosSizing: true,
              includeOos: true
            };
            fetchProductStyleDetails(currentStyleId, merchantId, fetchOpts);
            alert(error);
          } else {
            setProductCardState(ProductCardState.ADD_TO_CART_SUCCESS);
          }
          setTimeout(() => setProductCardState(ProductCardState.SIZE_PICK), 5000);
        }
      );
      track(() => [evAddToCart, { ...style, addedFrom: INFLUENCER_SHOPPABLE_POSTS_PAGE }]);
    } else {
      setProductCardState(ProductCardState.ADD_TO_CART_FAIL);
      setTimeout(() => setProductCardState(ProductCardState.SIZE_PICK), 5000);
    }
  };

  const onSelectingProduct = () => {
    setFocusedStyle(styleId);
    fireShoppablePostProductInteractionEvent(PRODUCT_INTERACTION_TYPE.Click, styleId, productId, colorId);
  };

  const onViewingMorePhotos = () => {
    setZoomedStyleId([styleId, currentStyleId]);
    fireShoppablePostProductInteractionEvent(PRODUCT_INTERACTION_TYPE.Zoom, currentStyleId, productId, colorId);
  };

  const onHeartClick = ({ styleId }: { styleId: string }, isHearted: boolean) => {
    if (isCustomer) {
      if (isHearted) {
        //@ts-expect-error INFLUENCER TEAM please address.
        unHeartProduct({ itemId: styleId, productId, colorId, sourcePage: INFLUENCER_SHOPPABLE_POSTS_PAGE });
      } else {
        //@ts-expect-error INFLUENCER TEAM please address.
        heartProduct({ itemId: styleId, productId, colorId, sourcePage: INFLUENCER_SHOPPABLE_POSTS_PAGE });
      }
    } else {
      toggleHeartingLoginModal(true, styleId);
    }
  };

  const resetProductNotifyState = () => {
    toggleProductNotifyModal(false);
  };

  const miniPageLoader = <SmallLoader />;

  const addToCartFailedMarkup = (
    <div className={css.atcFailedContainer} aria-live="polite">
      <div className={css.atcFailedHeaderContainer}>
        <span className={css.atcFailedIcon} aria-hidden={true} />
        <span className={css.atcFailedHeader}>{YOU_JUST_MISSED_IT}</span>
      </div>
      <span className={css.atcFailedMessage}>{SOLD_LAST_ITEM_IN_THIS_SIZE}</span>
    </div>
  );

  const addToCartSuccessMarkup = (
    <div className={css.addToCartSuccess} aria-live="polite">
      <span className={css.check} />
      Added To Bag
    </div>
  );

  const sizeSelectorMarkup = (
    <SizePickerWrapper
      setSelectedStockId={setSelectedStockId}
      onAddToCart={onAddToCart}
      sizing={sizing}
      colorId={colorId}
      selectedStyle={style}
      productData={productStyleDetails.productData}
      productId={productStyleDetails.productId?.toString()}
      resetProductNotifyState={resetProductNotifyState}
      sourcePage={INFLUENCER_SHOPPABLE_POSTS_PAGE}
    />
  );

  const getRatingPercentages = () =>
    possibleStarRatings.map((value: number) => (
      <div className={css.singleRating} key={`${styleId}-${value}`}>
        <label htmlFor={`${currentStyleId}-${value}`}>{value} Stars</label>
        <progress id={`${currentStyleId}-${value}`} value={overallRating?.[value as keyof ProductRating]} max={100} />
        <span className={css.fitPercentage}>{overallRating?.[value as keyof ProductRating] || '0'}%</span>
      </div>
    ));

  const getFitRatingMarkup = (percentage: string, text: string) => (
    <div className={css.fitRecord}>
      <span className={css.fitPercentage}>{percentage}%</span>
      <span className={css.textBase}>"{text}"</span>
    </div>
  );

  const ratingSummary = () => {
    const { percentage: archPercentage, text: archText } = maxArchRatingPercentage || { percentage: '', text: '' };
    const { percentage: sizePercentage, text: sizeText } = maxSizeRatingPercentage || { percentage: '', text: '' };
    const { percentage: widthPercentage, text: widthText } = maxWidthRatingPercentage || { percentage: '', text: '' };
    return (
      <div className={css.ratingContainer}>
        {totalReviews !== NO_TOTAL_REVIEWS && (
          <div className={css.ratings}>
            {totalReviews} Total Reviews
            {getRatingPercentages()}
          </div>
        )}
        <div className={css.sizeFitSummary}>
          {hasFitRatings === 'true' && (
            <>
              How It Fits
              {genericSizeBiases && <GenericSizeBiasReco currentProductId={productId.toString()} genericSizeBiases={genericSizeBiases} />}
              {sizePercentage.length && sizeText.length && getFitRatingMarkup(sizePercentage, sizeText)}
              {widthPercentage.length && widthText.length && getFitRatingMarkup(widthPercentage, widthText)}
              {archPercentage.length && archText.length && getFitRatingMarkup(archPercentage, archText)}
            </>
          )}
          <MartyLink to={productDetailUrl ?? productUrl} className={css.textBase}>
            See More Product Details
          </MartyLink>
        </div>
      </div>
    );
  };

  const isProductSelected = focusedStyle === styleId;
  const productLabel = generateLabelForProduct(productData.detail, style);

  const heartsProps = {
    showFavoriteHeart: true,
    onHeartClick,
    isDisplayCount: false,
    styleId: currentStyleId,
    productId
  };

  const defaultColorSwatchParams = {
    ...commonDefaultColorSwatchParams,
    relatedStyles: relatedSwatchData,
    makeSwatchClickHandler: (swatch: Product) => () => setCurrentStyleId(swatch.styleId),
    colorId: parseInt(colorId),
    styleId: currentStyleId,
    brandName: brandName!,
    productId: productId?.toString(),
    productName: productName!,
    productSeoUrl: seoProductUrl!,
    styleColor: color,
    productUrl,
    isNew,
    onSale,
    originalPrice,
    percentOff,
    price,
    color
  };

  return (
    <div className={css.productCard} ref={cardRef}>
      <div className={css.product}>
        <div className={css.imageStyleContainer}>
          <div className={cn(css.imageContainer, { [css.selectedImage]: isProductSelected })}>
            <button className={css.imageButton} onClick={onSelectingProduct} type="button" aria-label={productLabel}>
              <img
                src={constructMSAImageUrl(formattedStyles[parseInt(currentStyleId)]?.style.imageId, MSA_IMAGE_DIMENSIONS)}
                className={css.image}
                alt="Product"
              />
            </button>
            <Hearts {...heartsProps} />
          </div>
          <div className={css.swatchScroller}>
            <ColorSwatches {...defaultColorSwatchParams} />
          </div>
        </div>
        <div className={css.productDetails}>
          <div className={css.brandName}>{brandName}</div>
          <div className={css.productName}>{productName}</div>
          <div className={css.productColor}>Color: {color}</div>
          <div className={css.priceInfoContainer}>{style && <ProductPrice productStyle={style} />}</div>
          <div className={css.productRating}>
            <Rating countClass={css.reviewCount} rating={productRating} reviewCount={reviewCount} hasDisplayReviewCount={true} />
          </div>
          <button className={css.productLink} onClick={onViewingMorePhotos} type="button">
            View more photos
          </button>
          {!showRatingAccordion && (
            <MartyLink to={productDetailUrl ?? productUrl} className={css.productLink}>
              More product details
            </MartyLink>
          )}
        </div>
        <div className={cn(css.productSelects, { [css.flex]: productCardState === ProductCardState.ADD_TO_CART_FAIL })}>
          {
            {
              [ProductCardState.SIZE_PICK]: sizeSelectorMarkup,
              [ProductCardState.ADD_TO_CART_FAIL]: addToCartFailedMarkup,
              [ProductCardState.ADD_TO_CART_SUCCESS]: addToCartSuccessMarkup,
              [ProductCardState.ADD_TO_CART_IN_PROGRESS]: miniPageLoader
            }[productCardState]
          }
        </div>
      </div>
      {showRatingAccordion && (
        <div className={css.details}>
          <Accordion>
            <AccordionItem
              accordionRegionContainerStyleOverride={css.reviewAccordion}
              heading="Additional Product Details"
              accordionTestId="ProductInfo"
            >
              {ratingSummary()}
            </AccordionItem>
          </Accordion>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  const isCustomer = !!(ExecutionEnvironment.canUseDOM && state.cookies['x-main']);
  return { isCustomer };
};

const mapDispatchToProps = {
  changeQuantity,
  fetchProductStyleDetails,
  toggleProductNotifyModal,
  heartProduct,
  toggleHeartingLoginModal,
  unHeartProduct
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CustomerViewProductCard);
