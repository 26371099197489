import type { RefObject, SetStateAction } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type ReactPlayer from 'react-player';

import { cn } from 'helpers/classnames';
import { constructMSAImageUrl } from 'helpers/index';
import { useInterval } from 'hooks/useInterval';
import useEffectOnce from 'hooks/useEffectOnce';
import type { ContentImage, InfluencerShoppablePost, InfluencerState, ProductDetails, ProductMetadata, ProductStyleDetails } from 'types/influencer';
import { InfluencerContentType } from 'types/influencer';
import CustomerViewProductCard from 'components/influencer/shoppablePosts/CustomerViewProductCard';
import ProductGallery from 'components/productdetail/productGallery/ProductGallery';
import { getHeartCounts } from 'actions/hearts';
import {
  evShareShoppablePostClick,
  evShoppablePostProductClickthrough,
  evShoppablePostProductInteraction,
  evShoppableVideoActionEvent,
  pvShoppablePostPageView
} from 'events/influencerShoppablePost';
import { titaniteView, track } from 'apis/amethyst';
import { getAbsoluteMarketplaceUrl } from 'helpers/MarketplaceUtils';
import { generateShoppablePostShareObj } from 'helpers/ShoppablePostUtils';
import InfluencerLinkShareModal from 'components/influencer/InfluencerLinkShareModal';
import { PageLoader } from 'components/Loader';
import { PRODUCTS_RAN_OUT_OF_STOCK, YOU_JUST_MISSED_IT } from 'constants/influencerMessages';
import type { ProductStyle } from 'types/cloudCatalog';
import type { FormattedProductBundle } from 'reducers/detail/productDetail';
import { merchantIdHostMapping } from 'constants/crossSiteMerchantIdMapping';
import { INFLUENCER_SHOPPABLE_POSTS_PAGE } from 'constants/amethystPageTypes';
import { VIDEO_INTERACTION_TYPE } from 'constants/amethystEnums';
import AdaptiveBitratePlayer from 'components/influencer/shoppablePosts/AdaptiveBitratePlayer';
import { isSmoothScrollSupported } from 'helpers/dragScrollUtils';
import { fetchCustomerPostLikedStatus } from 'actions/influencer/influencerContent';
import { TITLE_ID, VIDEO_END, VIDEO_PAUSED, VIDEO_PLAY, VIDEO_PLAY_EVENT_TRIGGER, VIDEO_SEEK } from 'constants/shoppablePosts';
import type { AppDispatch } from 'entrypoints/bootstrapOnClient';
import type { AppState } from 'types/app';

import css from 'styles/components/influencer/shoppablePosts/customerView.scss';

enum ProductsOOSStatus {
  UNDEFINED = 'UNDEFINED',
  TRUE = 'TRUE',
  FALSE = 'FALSE'
}

interface OwnProps {
  mediaSource: string;
  shoppablePost: InfluencerShoppablePost;
  productStyleDetails: ProductStyleDetails;
  taggedProducts: ProductMetadata[];
  allowSharing?: boolean;
  showCart?: boolean;
  cartBtn?: JSX.Element;
  isQuickView?: boolean;
  className?: string;
  profileImage?: ContentImage;
  influencerDetails?: InfluencerState;
}

export const CustomerView = ({
  mediaSource,
  shoppablePost,
  productStyleDetails,
  taggedProducts,
  allowSharing = true,
  showCart = false,
  cartBtn,
  isQuickView = false,
  className = '',
  profileImage,
  influencerDetails
}: OwnProps) => {
  const dispatch: AppDispatch = useDispatch();
  const reactPlayerRef = useRef<ReactPlayer>();
  const scrollRef = React.useRef<HTMLDivElement>(null);

  const { title, description: postDescription, postType, postId } = shoppablePost;
  const { imageId: profileImageId, extension: profileImageExt } = profileImage || {};
  const { profileHandle, name: influencerName } = influencerDetails || {};
  const productStyleDetailValues = Object.values(productStyleDetails);
  const isSmoothScrollSupportedByBrowser = isSmoothScrollSupported(scrollRef);

  const [[parentStyleId, zoomedStyleId], setZoomedStyleId] = useState(['', '']);
  const [areAllProductsOOS, setAreAllProductsOOS] = useState<string>(ProductsOOSStatus.UNDEFINED);
  const [showShareModal, setShowShareModal] = useState(false);
  const [focusedStyle, setFocusedStyle] = useState('');
  const [videoStatus, setVideoStatus] = useState('');
  const [[showTags, showTagsAnimation], setShowTags] = useState([false, true]);
  const { protocol, host } = useSelector((state: AppState) => state.url);
  const baseUrl = `${protocol}://${host}`;
  useEffectOnce(() => {
    titaniteView();
    dispatch(fetchCustomerPostLikedStatus([postId]));
    if (!isQuickView) {
      track(() => [
        pvShoppablePostPageView,
        {
          shoppablePostId: postId,
          arrivedFromShareLink: true,
          shareToken: postId,
          shoppableContentType: postType
        }
      ]);
    }
  });

  useEffect(() => {
    setAreAllProductsOOS(ProductsOOSStatus.UNDEFINED);
    if (taggedProducts?.length && productStyleDetailValues?.length) {
      const checkIfProductIsInStock = (product: ProductDetails) => product?.isProductDataAvailable && !product?.isOOS;
      const atLeastOneProductInStock = productStyleDetailValues.some(checkIfProductIsInStock);
      setAreAllProductsOOS(atLeastOneProductInStock ? ProductsOOSStatus.FALSE : ProductsOOSStatus.TRUE);
      if (atLeastOneProductInStock && productStyleDetailValues.length) {
        const styleIds = new Set();
        productStyleDetailValues?.map((styleRecord: ProductDetails) => {
          if (styleRecord?.productData?.detail) {
            const { styles } = styleRecord?.productData?.detail;
            styles?.map(({ styleId }) => {
              styleIds.add({ styleId });
            });
          }
        });
        if (styleIds.size > 0) {
          dispatch(getHeartCounts(Array.from(styleIds)));
        }
      }
    }
  }, [Object.keys(productStyleDetails).length]);

  useInterval(() => {
    if (videoStatus === VIDEO_PLAY || videoStatus === VIDEO_SEEK) {
      fireVideoTrackingEvent(VIDEO_INTERACTION_TYPE.Play, reactPlayerRef.current?.getCurrentTime());
    }
  }, VIDEO_PLAY_EVENT_TRIGGER);

  const onZoomModalClose = () => {
    setZoomedStyleId(['', '']);
  };

  const onShareButtonClick = () => {
    setShowShareModal(true);

    track(() => [
      evShareShoppablePostClick,
      {
        shoppablePostId: postId,
        shareToken: postId,
        shoppableContentType: postType,
        sourcePage: INFLUENCER_SHOPPABLE_POSTS_PAGE
      }
    ]);
  };

  const fireShoppablePostProductClickThroughEvent = (styleId: string, productId: number) => {
    track(() => [
      evShoppablePostProductClickthrough,
      {
        styleId,
        productId,
        shoppablePostId: postId,
        shoppableContentType: postType,
        sourcePage: INFLUENCER_SHOPPABLE_POSTS_PAGE
      }
    ]);
  };

  const fireShoppablePostProductInteractionEvent = (interactionType: string, styleId: string, productId: number | undefined, colorId: string) => {
    track(() => [
      evShoppablePostProductInteraction,
      {
        shoppablePostId: postId,
        shoppableContentType: postType,
        sourcePage: INFLUENCER_SHOPPABLE_POSTS_PAGE,
        interactionType: interactionType,
        selectedProduct: { productId, styleId, colorId }
      }
    ]);
  };

  const makePageLoader = (
    <div className={css.productList}>
      <PageLoader centered={true} />
    </div>
  );

  const makeOOSContainer = (
    <div className={css.allOOSContainer}>
      <div className={css.oosMessageContainer}>
        <span className={css.oosHeader}>{YOU_JUST_MISSED_IT}</span>
      </div>
      <div className={css.oosMessageContainer}>
        <span className={css.oosMessage}>{PRODUCTS_RAN_OUT_OF_STOCK}</span>
      </div>
    </div>
  );

  const makeProductCards = (
    <div className={css.productList} ref={scrollRef}>
      {taggedProducts.map(({ styleId }, index: number) => {
        if (styleId in productStyleDetails) {
          return generateProductCard(
            host,
            index,
            styleId,
            focusedStyle,
            scrollRef,
            productStyleDetails[styleId],
            isSmoothScrollSupportedByBrowser,
            setFocusedStyle,
            isQuickView,
            setZoomedStyleId,
            fireShoppablePostProductClickThroughEvent,
            fireShoppablePostProductInteractionEvent
          );
        }
        return null;
      })}
    </div>
  );

  const fireVideoTrackingEvent = (videoAction: String, videoActionTime: number | undefined) => {
    track(() => [
      evShoppableVideoActionEvent,
      {
        shoppablePostId: postId,
        videoAction,
        videoActionTime
      }
    ]);
  };

  const makeProductZoomModal = (parentStyleId: string, zoomedStyleId: string) => {
    const { productData: { detail = {} as FormattedProductBundle } = {} } = productStyleDetails[parentStyleId] || {};
    const { styles = [], videos, youtubeVideoId, youtubeData: { embedUrl } = {} } = detail as FormattedProductBundle;
    const style = styles.find(style => style.styleId === zoomedStyleId) as ProductStyle;

    return (
      <ProductGallery
        style={style}
        product={detail}
        productVideos={videos}
        isYouTubeVideo={!!youtubeVideoId}
        youtubeSrc={embedUrl}
        isHydraPhotoAngles={false}
        isDefaultZoom={true}
        onZoomClose={onZoomModalClose}
      />
    );
  };
  const showShareLikeButton = (cssClassName: string) => (
    <div className={cssClassName}>
      <button onClick={onShareButtonClick} type="button" className={css.sharePost}>
        Share
      </button>
    </div>
  );

  return (
    <div className={cn(css.shoppableCustomerView, className, { [css.quickViewOverride]: isQuickView })}>
      <InfluencerLinkShareModal
        isModalOpen={showShareModal}
        toggleVisibility={() => setShowShareModal(false)}
        copyMessage="Shoppable Post Link Copied"
        objectToShare={generateShoppablePostShareObj(title, postId, baseUrl)}
        isLoadingFromVrsnl={false}
        onSharingButtonClick={() => {}}
      />
      {!!zoomedStyleId && makeProductZoomModal(parentStyleId, zoomedStyleId)}
      <div className={css.postHeader}>
        <h1 className={css.title} id={TITLE_ID}>
          {title}
        </h1>
        <p className={css.description}>{postDescription}</p>
        {allowSharing && showShareLikeButton(css.sharePostMobile)}
      </div>
      {allowSharing && showShareLikeButton(css.sharePostDesktop)}
      {showCart && <div className={css.cartBtnWrapper}>{cartBtn}</div>}

      <div className={css.postDetails}>
        {makeModalImage(
          postId,
          postType,
          mediaSource,
          influencerName,
          profileHandle,
          profileImageId,
          profileImageExt,
          reactPlayerRef,
          fireShoppablePostProductClickThroughEvent,
          fireVideoTrackingEvent,
          taggedProducts,
          productStyleDetails,
          focusedStyle,
          setFocusedStyle,
          setVideoStatus,
          showTags,
          showTagsAnimation,
          setShowTags
        )}
        {
          {
            [ProductsOOSStatus.UNDEFINED]: makePageLoader,
            [ProductsOOSStatus.TRUE]: makeOOSContainer,
            [ProductsOOSStatus.FALSE]: makeProductCards
          }[areAllProductsOOS]
        }
      </div>
    </div>
  );
};

const generateProductCard = (
  host: string,
  index: number,
  styleId: string,
  focusedStyle: string,
  scrollRef: RefObject<HTMLDivElement>,
  productStyleDetails: ProductDetails | undefined,
  isSmoothScrollSupportedByBrowser: boolean,
  setFocusedStyle: (input: SetStateAction<string>) => void,
  isQuickView: boolean,
  setZoomedStyleId: (input: SetStateAction<[string, string]>) => void,
  fireShoppablePostProductClickThroughEvent: (styleId: string, productId: number) => void,
  fireShoppablePostProductInteractionEvent: (interactionType: string, styleId: string, productId: number, colorId: string) => void
) => {
  if (!styleId || !productStyleDetails || !productStyleDetails.isProductDataAvailable) {
    return null;
  }

  const { merchantId, productUrl, productId } = productStyleDetails;
  const marketplaceName = merchantIdHostMapping[merchantId]!;
  const redirectBaseUrl = getAbsoluteMarketplaceUrl(marketplaceName, host);
  const productDetailUrl = `${redirectBaseUrl}${productUrl}`;

  return (
    <CustomerViewProductCard
      key={`${styleId}-${index}`}
      productId={productId}
      styleId={styleId}
      focusedStyle={focusedStyle}
      setFocusedStyle={setFocusedStyle}
      setZoomedStyleId={setZoomedStyleId}
      productStyleDetails={productStyleDetails}
      productDetailUrl={productDetailUrl}
      merchantId={merchantId}
      fireShoppablePostProductInteractionEvent={fireShoppablePostProductInteractionEvent}
      fireShoppablePostProductClickThroughEvent={fireShoppablePostProductClickThroughEvent}
      scrollRef={scrollRef}
      isQuickView={isQuickView}
      isSmoothScrollSupportedByBrowser={isSmoothScrollSupportedByBrowser}
    />
  );
};

const makeModalImage = (
  postId: string,
  postType: string,
  mediaSource: string,
  influencerName: string | undefined,
  profileHandle: string | undefined,
  profileImageId: string | undefined,
  profileImageExt: string | undefined,
  reactPlayerRef: React.MutableRefObject<ReactPlayer | undefined>,
  fireShoppablePostProductClickThroughEvent: (styleId: string, productId: number) => void,
  fireVideoTrackingEvent: (videoAction: String, videoActionTime: number | undefined) => void,
  taggedProducts: ProductMetadata[],
  productStyleDetails: ProductStyleDetails,
  focusedStyle: string,
  setFocusedStyle: (input: SetStateAction<string>) => void,
  setVideoStatus: (input: SetStateAction<string>) => void,
  showTags: boolean,
  showTagsAnimation: boolean,
  setShowTags: (input: SetStateAction<[boolean, boolean]>) => void
) => {
  const isBrandContent = postType === InfluencerContentType.BRAND_SHOPPABLE_PHOTO;
  const InfluencerDetails = (
    <div className={css.profileFollowContainer}>
      <div className={css.profileContainer}>
        <img alt="Profile" className={css.profileImage} src={constructMSAImageUrl(profileImageId, { extension: profileImageExt })} />
        <div className={css.profileDetails}>
          <span className={css.name}>{influencerName}</span>
          <span className={css.profileHandle}>@{profileHandle}</span>
        </div>
      </div>
    </div>
  );

  const tags = taggedProducts.map((tag: ProductMetadata) => {
    const { styleId: tagStyleId, tagCoordinateY, tagCoordinateX } = tag;
    const taggedProduct = productStyleDetails[tagStyleId];
    const imageId = taggedProduct?.imageId;
    if (!imageId) {
      // Product is Unavailable
      return;
    }

    const onProductFocus = () => {
      setFocusedStyle(tagStyleId);
      fireShoppablePostProductClickThroughEvent(tagStyleId, productStyleDetails[tagStyleId]!.productId);
    };
    const isTagHighlighted = tagStyleId === focusedStyle;
    const tagPosition = {
      left: isTagHighlighted ? `calc(${tagCoordinateX}% - 12px)` : `calc(${tagCoordinateX}% - 8px)`,
      top: isTagHighlighted ? `calc(${tagCoordinateY}% - 12px)` : `calc(${tagCoordinateY}% - 8px)`
    };
    return (
      <button
        key={`${tagCoordinateX}-${tagCoordinateY}`}
        className={cn(css.tags, { [css.activeTag]: isTagHighlighted, [css.hideTag]: !showTags, [css.animateTag]: showTagsAnimation })}
        type="button"
        style={tagPosition}
        onClick={onProductFocus}
        aria-hidden={true}
        tabIndex={-1}
      />
    );
  });

  const onVideoActionEvent = (videoState: string) => {
    setVideoStatus(videoState);
    let interactionType = '';
    switch (videoState) {
      case VIDEO_PLAY:
        interactionType = VIDEO_INTERACTION_TYPE.Play;
        break;
      case VIDEO_PAUSED:
        interactionType = VIDEO_INTERACTION_TYPE.Pause;
        break;
      case VIDEO_SEEK:
        interactionType = VIDEO_INTERACTION_TYPE.Seeked;
        break;
      case VIDEO_END:
        interactionType = VIDEO_INTERACTION_TYPE.End;
        break;
    }
    fireVideoTrackingEvent(interactionType, reactPlayerRef.current?.getCurrentTime());
  };

  if ([InfluencerContentType.SHOPPABLE_PHOTO.toString(), InfluencerContentType.BRAND_SHOPPABLE_PHOTO.toString()].includes(postType)) {
    return (
      <div className={css.imageContainer}>
        <div
          className={cn(css.content, { [css.brandContentOverride]: isBrandContent })}
          onMouseEnter={() => setShowTags([true, false])}
          onMouseLeave={() => setShowTags([false, false])}
        >
          <img className={css.contentImage} src={mediaSource} alt="Shoppable Post Content" />
          {tags}
        </div>
        {!!(influencerName && profileHandle && profileImageId) && InfluencerDetails}
      </div>
    );
  } else if ([InfluencerContentType.SHOPPABLE_VIDEO.toString(), InfluencerContentType.BRAND_SHOPPABLE_VIDEO.toString()].includes(postType)) {
    return (
      <div className={css.videoContainer}>
        <AdaptiveBitratePlayer
          videoSrc={mediaSource}
          videoPlayerCss={css.videoPlayer}
          height="100%"
          width="100%"
          onVideoPlay={() => onVideoActionEvent(VIDEO_PLAY)}
          onVideoPause={() => onVideoActionEvent(VIDEO_PAUSED)}
          onVideoSeek={() => onVideoActionEvent(VIDEO_SEEK)}
          onEnded={() => onVideoActionEvent(VIDEO_END)}
          playerRef={reactPlayerRef}
        />
        {!!(influencerName && profileHandle && profileImageId) && InfluencerDetails}
      </div>
    );
  }

  return null;
};
