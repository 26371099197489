/* eslint-disable import/no-unresolved */
import type { FunctionComponent, MouseEventHandler } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import type { EmblaCarouselType } from 'embla-carousel-react';

import { cn } from 'helpers/classnames';
import useWindowSize from 'hooks/useWindowSize';
import useMartyContext from 'hooks/useMartyContext';
import { getPDPTrackingPropsFormatted, PDP_GALLERY_CONFIG } from 'helpers/ProductUtils';
import type { FeaturedImage, PDPCarouselRef, PDPFeaturedVideo } from 'types/product';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import videoThumbnail from 'images/videoThumbnail.svg';
import { track } from 'apis/amethyst';
import { evImageThumbnail } from 'events/product';
import { useIsComponentMounted } from 'hooks/useIsComponentMounted';
import { useInAssignment } from 'hooks/useHydra';
import { HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS } from 'constants/hydraTests';

import css from 'styles/components/productdetail/productGallery/productGalleryThumbnails.scss';

interface NavArrowProps {
  label: string;
  zoomIsActive: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

/**
 * PDP Assets gallery thumbnails carousel arrow controls
 * @returns {FunctionComponent}
 */
const NavArrow: FunctionComponent<NavArrowProps> = ({ label, zoomIsActive, onClick }) => {
  const { testId } = useMartyContext();
  return (
    <button
      type="button"
      onClick={onClick}
      aria-label={label}
      data-test-id={testId('imageAngleScrollButton')}
      className={cn(css.nav, { [css.zoomMode]: zoomIsActive })}
      {...getPDPTrackingPropsFormatted(`Arrow-${label}`, 'Button-Click')}
    />
  );
};

interface ThumbnailImageProps {
  image: FeaturedImage;
  prependedAltText?: string;
}

/**
 * PDP Assets gallery thumbnail image
 * @returns {FunctionComponent}
 */
const ThumbnailImage: FunctionComponent<ThumbnailImageProps> = ({ image, prependedAltText }) => (
  <picture>
    {image.thumbnail.webp && <source srcSet={`${image.thumbnail.webp.src} 1x, ${image.thumbnail.webp.retinaSrc}`} type="image/webp " />}
    <source srcSet={`${image.thumbnail.src} 1x, ${image.thumbnail.retinaSrc}`} type="image/jpeg " />
    <img alt={(prependedAltText && `${prependedAltText} - `) + image.alt} src={image.thumbnail.src} srcSet={image.thumbnail.retinaSrc} />
  </picture>
);

interface ThumbnailVideoProps {
  video: PDPFeaturedVideo;
}

/**
 * PDP Assets gallery thumbnail image
 * @returns {FunctionComponent}
 */
const ThumbnailVideo: FunctionComponent<ThumbnailVideoProps> = ({ video }) => <img alt={video.alt} src={videoThumbnail} />;

function makeThumbnailKey(asset: FeaturedImage | PDPFeaturedVideo): string {
  if (asset.type === 'image') {
    return asset.imageId + asset.angleType;
  }
  // asset.type === 'video'
  return (asset.slotDetails?.src || '') + asset.angleType;
}

interface Props {
  zoomIsActive: boolean;
  selectedAsset: number;
  productAssets: (FeaturedImage | PDPFeaturedVideo)[];
  productThumbnailsRef: PDPCarouselRef;
  productFeaturedCarousel: EmblaCarouselType | undefined;
  productThumbnailsCarousel: EmblaCarouselType | undefined;
  hasHorizontalThumbnails?: boolean;
  prependedAltText?: string;
  onThumbnailSelected?: (selectedAssetIndex: number) => void;
}

/**
 * PDP Assets gallery thumbnails container (carousel controls + thumbnail image + thumbnails video)
 * @returns {FunctionComponent}
 */
const ProductGalleryThumbnails: FunctionComponent<Props> = ({
  zoomIsActive,
  selectedAsset,
  productAssets,
  productThumbnailsRef,
  productThumbnailsCarousel,
  productFeaturedCarousel,
  hasHorizontalThumbnails,
  prependedAltText,
  onThumbnailSelected
}) => {
  const { testId } = useMartyContext();
  const isComponentMounted = useIsComponentMounted();
  const isHydraCefi = useInAssignment(HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS);

  // === CAROUSEL CONFIG
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  useEffect(() => {
    if (windowHeight && windowWidth && productThumbnailsCarousel) {
      const debounced = debounce(() => {
        productThumbnailsCarousel.reInit({
          // Variable settings
          axis: windowWidth > PDP_GALLERY_CONFIG.carouselThreshold && !hasHorizontalThumbnails ? 'y' : 'x', // View port determines vertical/horizontal thumbs
          containScroll: 'keepSnaps',
          skipSnaps: true
        });
      }, 250);
      debounced();
    }
  }, [windowHeight, windowWidth, zoomIsActive, productThumbnailsCarousel, hasHorizontalThumbnails]);

  // ==== CAROUSEL EVENTS
  const [isReady, setIsReady] = useState(false);
  const onInit = useCallback(() => {
    const timeout = setTimeout(() => {
      if (!isComponentMounted) {
        return;
      }
      setIsReady(true);
    }, 750);
    if (!isComponentMounted) {
      clearTimeout(timeout);
    }
  }, [isComponentMounted]);

  useEffect(() => {
    productThumbnailsCarousel?.on('init', onInit);
    return function cleanup() {
      productThumbnailsCarousel?.off('init', onInit);
    };
  }, [onInit, productThumbnailsCarousel]);

  // Whether or not the arrows should be displayed
  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(false);
  // TODO remove this eslint exception when https://github01.zappos.net/mweb/marty/issues/17305 is addressed
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkArrowsNeeded = () => {
    if (windowWidth && productThumbnailsCarousel) {
      const scroll = productThumbnailsCarousel.scrollProgress();
      if (Math.abs(scroll) !== Infinity) {
        setShowNext(scroll < 0.95);
        setShowPrev(scroll > 0.05);
      }
    }
  };
  useEffect(() => {
    productThumbnailsCarousel?.on('reInit', checkArrowsNeeded);
    productThumbnailsCarousel?.on('settle', checkArrowsNeeded);
    productThumbnailsCarousel?.on('resize', checkArrowsNeeded);

    return function cleanup() {
      productThumbnailsCarousel?.off('reInit', checkArrowsNeeded);
      productThumbnailsCarousel?.off('settle', checkArrowsNeeded);
      productThumbnailsCarousel?.off('resize', checkArrowsNeeded);
    };
  }, [checkArrowsNeeded, productThumbnailsCarousel]);

  // When the thumbnail is selected, the correspondent featured item is selected as well
  const onThumbnailSelect = (index: number, src: string | null, alt: string) => {
    src && track(() => [evImageThumbnail, { src, alt }]);
    onThumbnailSelected && onThumbnailSelected(index);
    productFeaturedCarousel?.scrollTo(index);
    productThumbnailsCarousel?.scrollTo(index);
  };

  // Whether or not the thumb container is scrollable
  const isScrollable = !isReady || (!showPrev && !showNext);

  return (
    <div
      id="productThumbnails"
      className={cn(css.productThumbnails, {
        [css.zoomMode]: zoomIsActive,
        [css.horizontal]: hasHorizontalThumbnails
      })}
    >
      <div className={css.carousel} ref={productThumbnailsRef}>
        <ul
          className={cn(css.slides, {
            [css.zoomMode]: zoomIsActive,
            [css.mounting]: isScrollable
          })}
        >
          {productAssets.map(asset => (
            <li className={css.slide} key={makeThumbnailKey(asset)}>
              <button
                type="button"
                aria-label={asset.alt}
                aria-current={selectedAsset === asset.index}
                onClick={() => onThumbnailSelect(asset.index, 'thumbnail' in asset ? asset.thumbnail.src : null, asset.alt)}
                data-media={asset.type}
                data-test-id={testId(`${asset.type}Thumbnail`)}
                className={cn(css.slideThumb, { [css.zoomMode]: zoomIsActive, [css.darkerSlideThumb]: isHydraCefi })}
                {...getPDPTrackingPropsFormatted(`Thumbnail-${asset.type}`, `${asset.type}-Click`)}
              >
                {'thumbnail' in asset ? <ThumbnailImage image={asset} prependedAltText={prependedAltText} /> : <ThumbnailVideo video={asset} />}
              </button>
            </li>
          ))}
        </ul>
      </div>
      {showPrev && (
        <NavArrow
          label={'previous'}
          zoomIsActive={zoomIsActive}
          onClick={() =>
            productThumbnailsCarousel?.scrollTo(productThumbnailsCarousel.selectedScrollSnap() - productThumbnailsCarousel.slidesInView().length)
          }
        />
      )}
      {showNext && (
        <NavArrow
          label={'next'}
          zoomIsActive={zoomIsActive}
          onClick={() =>
            productThumbnailsCarousel?.scrollTo(productThumbnailsCarousel.selectedScrollSnap() + productThumbnailsCarousel.slidesInView().length)
          }
        />
      )}
    </div>
  );
};

export default withErrorBoundary('ProductGalleryThumbnails', ProductGalleryThumbnails);
