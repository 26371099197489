import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AdaptiveBitratePlayer from 'components/influencer/shoppablePosts/AdaptiveBitratePlayer';
import ProductCard from 'components/common/ProductCard';
import type { InfluencerContent } from 'types/influencer';
import { InfluencerContentType } from 'types/influencer';
import { getMediaUrl } from 'helpers/InfluencerUtils';
import QuickViewModal from 'components/influencer/shoppablePosts/QuickViewModal';
import { fetchProductMetadataForAllProducts } from 'actions/influencer/shoppablePost';
import { convertContentToShoppablePost } from 'helpers/ShoppablePostUtils';
import useEffectOnce from 'hooks/useEffectOnce';
import { track } from 'apis/amethyst';
import { BrandShopTheLookPlacementType, INFLUENCER_CONTENT_TYPE } from 'constants/amethystEnums';
import { MSA_SETTINGS_JPG_85, SHOP_THE_LOOK_HEADING } from 'constants/shoppablePosts';
import { evBrandShopTheLookCardClick, evBrandShopTheLookSearchImpression } from 'events/influencerShoppablePost';
import { evStyleFeedCardClick } from 'events/styleFeed';
import type { AppDispatch } from 'entrypoints/bootstrapOnClient';
import type { AppState } from 'types/app';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/influencer/shoppablePosts/inlineBrandInfluencerShoppablePostCard.scss';

const CARD_INDEX_IN_ROW = 1;

interface InlineBrandInfluencerShoppablePostCardProps {
  content: InfluencerContent;
  className?: string;
  cardIndex?: number;
  searchTerm?: string;
  slotPosition?: number;
  isRenderedOnBanner?: boolean;
  isRenderedOnStyleFeed?: boolean;
  isRenderedOnCarousel?: boolean;
  sourcePage: string;
  allowSharing?: boolean;
}

export const InlineBrandInfluencerShoppablePostCard = ({
  content,
  className,
  searchTerm,
  slotPosition,
  cardIndex = 0,
  isRenderedOnBanner = false,
  isRenderedOnStyleFeed = false,
  isRenderedOnCarousel = false,
  sourcePage,
  allowSharing = false
}: InlineBrandInfluencerShoppablePostCardProps) => {
  const { contentId, contentType, mediaMetadata, additionalMetadata = {} } = content;
  const { brandName = '', subHeading = '' } = additionalMetadata;
  const { mediaId, mediaExtension, productMetadata: productMetadataList } = mediaMetadata[0] || {};
  const shoppablePost = convertContentToShoppablePost(content);
  const isShoppableVideo = contentType === InfluencerContentType.BRAND_SHOPPABLE_VIDEO || contentType === InfluencerContentType.SHOPPABLE_VIDEO;
  const mediaUrl = isShoppableVideo
    ? mediaMetadata[0]?.mediaUrl
    : getMediaUrl(mediaId, mediaExtension, { width: 580, customSettings: MSA_SETTINGS_JPG_85 });
  const mediaSourceQVM = isShoppableVideo
    ? mediaMetadata[0]?.mediaUrl
    : getMediaUrl(mediaId, mediaExtension, { width: 980, customSettings: MSA_SETTINGS_JPG_85 });

  const dispatch: AppDispatch = useDispatch();
  const [isQVModalOpen, setIsQVModalOpen] = useState(false);
  const productStyleDetails = useSelector((state: AppState) => state.influencer.productStyleDetails);
  const { testId } = useMartyContext();

  const isSingleCardRendered = !(isRenderedOnBanner || isRenderedOnStyleFeed || isRenderedOnCarousel);

  useEffectOnce(() => {
    if (content && isSingleCardRendered) {
      track(() => [
        evBrandShopTheLookSearchImpression,
        {
          placementType: BrandShopTheLookPlacementType.CARD,
          position: slotPosition,
          searchTerm,
          contents: [
            {
              contentId,
              contentType: INFLUENCER_CONTENT_TYPE[contentType],
              brandName,
              index: CARD_INDEX_IN_ROW
            }
          ],
          sourcePage
        }
      ]);
    }
  });

  if (!mediaUrl) {
    return null;
  }

  const makeQuickViewModal = () => {
    if (!(mediaMetadata?.length && mediaId && mediaExtension && productMetadataList)) {
      return;
    }

    return (
      <QuickViewModal
        mediaSource={mediaSourceQVM || ''}
        shoppablePost={shoppablePost}
        productStyleDetails={productStyleDetails}
        taggedProducts={productMetadataList}
        onModalClose={closeQuickViewModal}
        allowSharing={allowSharing}
        showCart={true}
        showContinueBtn={true}
        showShopMoreLooksLink={!isRenderedOnStyleFeed}
        shouldCleanupOnUnmount={true}
      />
    );
  };

  const fireCardClickEvent = () => {
    const placementType = isRenderedOnCarousel
      ? BrandShopTheLookPlacementType.CAROUSEL
      : isRenderedOnBanner
      ? BrandShopTheLookPlacementType.BANNER
      : BrandShopTheLookPlacementType.CARD;
    const index = isSingleCardRendered ? CARD_INDEX_IN_ROW : cardIndex + 1;

    if (isRenderedOnStyleFeed) {
      track(() => [
        evStyleFeedCardClick,
        {
          contentId,
          contentType,
          cardIndex: index
        }
      ]);
    } else {
      track(() => [
        evBrandShopTheLookCardClick,
        {
          placementType,
          position: slotPosition,
          searchTerm,
          content: {
            contentId,
            contentType: INFLUENCER_CONTENT_TYPE[contentType],
            brandName,
            index
          },
          sourcePage
        }
      ]);
    }
  };

  const openQuickViewModal = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    fireCardClickEvent();
    const fetchOpts = {
      entireProduct: true,
      includeSizing: true,
      includeOos: true
    };
    dispatch(fetchProductMetadataForAllProducts(mediaMetadata, fetchOpts));
    setIsQVModalOpen(true);
  };

  const closeQuickViewModal = () => {
    setIsQVModalOpen(false);
  };

  const shopTheLookHeader = () => <div className={css.shopText}>{SHOP_THE_LOOK_HEADING}</div>;

  const linkProps = { tabIndex: 0, to: '#' };
  const mediaProps = {
    mainImage: {
      src: mediaUrl,
      srcSet: `${mediaUrl} 1x, ${mediaUrl} 2x`
    },
    fullSizeImage: true,
    imageNoBackground: true,
    imageClassName: css.cardMedia
  };

  return (
    <>
      {isQVModalOpen && makeQuickViewModal()}
      {contentType === InfluencerContentType.SHOPPABLE_PHOTO || contentType === InfluencerContentType.BRAND_SHOPPABLE_PHOTO ? (
        <ProductCard
          className={className}
          CardDetailsTopSlot={isSingleCardRendered ? shopTheLookHeader : null}
          brandName={brandName}
          productName={isRenderedOnBanner ? '' : subHeading}
          preLabel={SHOP_THE_LOOK_HEADING}
          showPrice={false}
          onClick={(e: React.MouseEvent<HTMLElement>) => openQuickViewModal(e)}
          linkProps={linkProps}
          showProductTag={true}
          productTagCount={productMetadataList?.length}
          data-test-id={testId('productCard')}
          {...mediaProps}
        />
      ) : (
        <button type="button" className={css.videoContainer} onClick={(e: React.MouseEvent<HTMLElement>) => openQuickViewModal(e)}>
          <AdaptiveBitratePlayer
            videoPlayerCss={css.videoPlayer}
            videoSrc={mediaUrl}
            autoPlay={true}
            videoControls={false}
            videoLoop={true}
            muted={true}
            width="unset"
            height="unset"
          />
        </button>
      )}
    </>
  );
};

export default InlineBrandInfluencerShoppablePostCard;
