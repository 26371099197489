import type { Dispatch, FunctionComponent } from 'react';
import React from 'react';
import { TransformWrapper } from 'react-zoom-pan-pinch';

import ImageZoomControls, { DEFAULT_ZOOM } from './ImageZoomControls';

import type { FeaturedImage } from 'types/product';

export interface ImageZoomProps {
  image: FeaturedImage;
  showInstructions: boolean;
  setShowInstructions: Dispatch<React.SetStateAction<boolean>>;
}
/**
 * PDP Assets gallery asset zoom
 * @see https://github.com/prc5/react-zoom-pan-pinch
 * @returns {FunctionComponent}
 */
export const ImageZoom: FunctionComponent<ImageZoomProps> = ({ image, showInstructions, setShowInstructions }) => {
  const ZOOM_CONTROLS = {
    step: 12
  };
  const DISABLE_CONTROLS = {
    disabled: true
  };
  return (
    <TransformWrapper
      initialScale={DEFAULT_ZOOM}
      pinch={ZOOM_CONTROLS}
      centerOnInit={true}
      wheel={DISABLE_CONTROLS}
      minScale={0.8}
      maxScale={8}
      limitToBounds={false}
    >
      <ImageZoomControls image={image} showInstructions={showInstructions} setShowInstructions={setShowInstructions} minScale={0.8} maxScale={8} />
    </TransformWrapper>
  );
};

export default ImageZoom;
