import type { XYCoord } from 'react-dnd';

export const handleDragScroll = (clientOffset: XYCoord, edgeSize: number, maxStepToScroll: number) => {
  if (!(clientOffset && edgeSize && maxStepToScroll)) {
    return false;
  }
  const {
    documentElement: { clientHeight: screenHeight, scrollHeight: documentHeight }
  } = document;
  const { pageXOffset: currentScrollX, pageYOffset: currentScrollY } = window;
  const { y: clientOffsetY } = clientOffset;

  const isInTopEdge = clientOffsetY < edgeSize;
  const isInBottomEdge = clientOffsetY > screenHeight - edgeSize;

  if (!(isInTopEdge || isInBottomEdge)) {
    return false;
  }

  const maxScrollY = documentHeight - screenHeight;

  // Determine if the window can be scrolled in up/down direction.
  const canScrollUp = currentScrollY > 0;
  const canScrollDown = currentScrollY < maxScrollY;

  let intensity = 0;
  let nextScrollY = currentScrollY;

  // Should we scroll up?
  if (isInTopEdge && canScrollUp) {
    intensity = (edgeSize - clientOffsetY) / edgeSize;
    nextScrollY = nextScrollY - maxStepToScroll * intensity;

    // Should we scroll down?
  } else if (isInBottomEdge && canScrollDown) {
    intensity = (clientOffsetY - (screenHeight - edgeSize)) / edgeSize;
    nextScrollY = nextScrollY + maxStepToScroll * intensity;
  }

  nextScrollY = Math.max(0, Math.min(maxScrollY, nextScrollY));

  if (nextScrollY !== currentScrollY) {
    window.scrollTo(currentScrollX, nextScrollY);
    return true;
  } else {
    return false;
  }
};

export function smoothScroll(element: HTMLElement, time: number, totalScrollValue: number, isVerticalScroll: boolean = true) {
  const SMOOTH_SCROLL_INTERVAL = 1000;
  const SMOOTH_SCROLL_BY = 2;
  function smoothHorizontalScroll(scrollValue: number) {
    isVerticalScroll ? element.scrollBy(0, scrollValue) : element.scrollBy(scrollValue, 0);
  }

  const scrollValue = totalScrollValue > 0 ? SMOOTH_SCROLL_BY : -1 * SMOOTH_SCROLL_BY;
  let scrollPosition = 0;
  let currentTime = 0;

  while (currentTime <= time && scrollPosition < Math.abs(totalScrollValue)) {
    setTimeout(() => smoothHorizontalScroll(scrollValue), currentTime, scrollValue, totalScrollValue);
    currentTime += time / SMOOTH_SCROLL_INTERVAL;
    scrollPosition += Math.abs(scrollValue);
  }
}

export const isSmoothScrollSupported = (scrollRef: React.RefObject<HTMLDivElement>) => {
  if (!scrollRef.current) {
    return false;
  }
  const { current: scrollContainer } = scrollRef;
  const {
    style: { scrollBehavior: scrollSave }
  } = scrollContainer;
  scrollContainer.style.scrollBehavior = 'smooth';
  const hasSmoothScroll = window.getComputedStyle(scrollContainer).scrollBehavior === 'smooth';
  scrollContainer.style.scrollBehavior = scrollSave;
  return hasSmoothScroll;
};

/** @todo - Check if function is even being used, and if not, remove it. */
// Vertical Scrolling Support
export const autoScrollToElement = (elementRef: any, scrollRef: any, isSmoothScrollSupportedByBrowser: boolean) => {
  const SMOOTH_SCROLL_DURATION = 2500;
  const {
    current: { offsetTop: elementOffTop },
    current: element
  } = elementRef;
  const {
    current: { offsetHeight: scrollSectionVisibleHeight, offsetTop: scrollSectionTop, scrollTop: currentScrollPosition },
    current: scrollElement
  } = scrollRef;
  const { bottom: elementBottom } = element?.getBoundingClientRect();
  const { top: scrollSectionAbsoluteTop } = scrollElement?.getBoundingClientRect();
  if (currentScrollPosition > elementOffTop - scrollSectionTop || elementBottom > scrollSectionVisibleHeight + scrollSectionAbsoluteTop) {
    if (currentScrollPosition > elementOffTop - scrollSectionTop) {
      const valueToScroll = currentScrollPosition - (elementOffTop - scrollSectionTop);
      if (isSmoothScrollSupportedByBrowser) {
        scrollRef.current?.scrollBy(0, -valueToScroll);
      } else {
        smoothScroll(scrollRef?.current, SMOOTH_SCROLL_DURATION, -valueToScroll);
      }
    } else {
      const valueToScroll = elementBottom - scrollSectionVisibleHeight - scrollSectionAbsoluteTop;
      if (isSmoothScrollSupportedByBrowser) {
        scrollRef.current?.scrollBy(0, valueToScroll);
      } else {
        smoothScroll(scrollRef?.current, SMOOTH_SCROLL_DURATION, valueToScroll);
      }
    }
  }
};
