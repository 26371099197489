import React from 'react';

import { cn } from 'helpers/classnames';
import { formatDropdownNames } from 'helpers/ShoppablePostUtils';
import type { SelectMenuOption } from 'types/influencer';
import { ProductNotifyModal } from 'components/productdetail/asyncProductPageModals';
import type { SizePickerProps } from 'components/influencer/shoppablePosts/SizePickerWrapper';

import css from 'styles/components/influencer/shoppablePosts/sizePicker.scss';

export const SizePicker = ({
  isAddToCartEnabled,
  onAddToCart,
  colorId,
  productId,
  selectedDimensions,
  dimensionsSet,
  dimensionsSetLength,
  dimensionIdToName,
  dimensionIdToUnitId,
  valuesSet,
  valueIdToName,
  inStockValues,
  lowStockMessage,
  isSelectedDimensionsOOS,
  showOOSModal,
  openProductNotifyModal,
  onProductNotifyModalClose,
  createDropdown
}: SizePickerProps) => (
  <div className={css.sizeChooserSection}>
    {dimensionsSet.map((dimension, index) => {
      const dimensionName = dimensionIdToName[dimension];
      const dimensionValues = valuesSet[dimension]![dimensionIdToUnitId[dimension]!]!;
      const dropdownOptions: SelectMenuOption[] = formatDropdownNames(dimensionValues, valueIdToName, inStockValues[dimension]);
      return (
        <div
          key={dimension}
          className={cn(
            css.sizeChooserControlWrapper,
            { [css.sizeChooserCompleteRow]: dimensionsSetLength === 2 && index === 0 }, // In mobile view first dimension should occupy full row when there are 2 possible dimensions in dimension set
            { [css.sizeChooserPartialRow]: (dimensionsSetLength === 2 && index === 1) || dimensionsSetLength === 1 } // In mobile view it should occupy partial row for second dimension when there are 2 possible dimensions in dimension set or for only one dimension in dimension set
          )}
        >
          {createDropdown(dropdownOptions, dimensionName!, dimension, index)}
        </div>
      );
    })}
    <div className={css.addToCartWrapper}>
      {lowStockMessage && <span className={css.stockMessage}>{lowStockMessage}</span>}
      <button
        onClick={isSelectedDimensionsOOS ? openProductNotifyModal : onAddToCart}
        type="button"
        tabIndex={!isAddToCartEnabled ? -1 : 0}
        className={cn(css.addToCart, { [css.disableAddToCart]: !isAddToCartEnabled })}
      >
        {isSelectedDimensionsOOS ? 'Out Of Stock' : 'Add To Bag'}
      </button>
    </div>
    {showOOSModal && (
      <ProductNotifyModal
        isOpen={showOOSModal}
        onClose={onProductNotifyModalClose}
        productId={productId}
        colorId={colorId}
        selectedSizing={selectedDimensions}
      />
    )}
  </div>
);

export default SizePicker;
